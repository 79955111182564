import { configureStore } from "@reduxjs/toolkit";
import ConnectionReducer from "./Connection";
// import logger from 'redux-logger';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import storageSession from "redux-persist/lib/storage/session";

const persistConfig = {
  key: "connection",
  storage: storageSession,
  whitelist: [
    "audioEnabled",
    "currentUserId",
    "selecteduser",
    "videoEnabled",
    "mainMicMute",
    "boothmatesMute",
    "boothmateVolume",
    "boothmateAudio",
    "videoInputDeviceId",
    "audioInputDeviceId",
    "audioOutputDeviceId",
    "selectedTab",
    "event_id",
    "participant_type",
    "displayName",
    "booth_language_id",
    "isEventJoined",
    "participantId",
    "themeMode",
    "enableTextChat",
    "brickLimiter",
    "thresholdValue",
    "attackValue",
    "releaseValue",
    "pregainValue",
    "postgainValue",
    "defaultBrickLimiter",
    "chimeEnabled",
    "showSettings",
    "showQuickMessages",
    "chimeVolume",
    "chatView",
    "allAudioVolumeForMod",
    "interpreterSortListType",
    "monitoringMode",
    "selectedVirtualCableId",
    "zoomWebxAudioMute",
    "zoomWebxVolume",
  ],
};

const persistedReducer = persistReducer(persistConfig, ConnectionReducer);

export const store = configureStore({
  reducer: {
    connection: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      // 	// Ignore these action types
      // 	ignoredActions: ['connection/setConnection', 'connection/localTracksAdded', 'connection/connectionEstablished', 'connection/conferenceJoined', 'connection/remoteTrackAdded'],
      // },
    }),
  devTools: process.env.NODE_ENV !== "production",
});
