import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PreJoinedControll from "../components/LandingPage/PreJoinedControll";
import {
  joinService,
  getEventDetails,
  interpreterJoinService,
  clientJoinService,
  moderatorJoinService,
} from "../Services/interceptorServices";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import {
  setDisplayNameAction,
  setBoothIdAction,
  setParticipantIdAction,
  setEventJoinedAction,
  setParticipantTypeAction,
  setEventIdAction,
  setInitialStateAction,
  setAudioEnabledAction,
  setVideoEnabledAction,
  setEnableTextChatAction,
} from "../Redux/Reducers/Connection";

const LandingPage = () => {
  const dispatch = useDispatch();
  const {
    event_id,
    booth_language_id,
    participant_type,
    displayName,
    videoInputDeviceId,
    audioInputDeviceId,
    audioOutputDeviceId,
  } = useSelector((state) => state.connection);
  const [isError, setIsError] = useState(false);
  const [invalidAccessCode, setInvalidAccessCode] = useState(false);
  const [loader, setLoader] = useState(true);
  const [accessCode, setAccessCode] = useState("");
  const [boothList, setBoothList] = useState({});
  const [isAgree, setIsAgree] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [eventDetail, setEventDetail] = useState({});

  const [postData, setPostData] = useState({
    event_id: "",
    name: displayName || "",
    booth_language_id: "",
    audio_in_device: "default",
    audio_out_device: "default",
    camera: "default",
    timezone: "Asia/Kolkata", //Intl.DateTimeFormat().resolvedOptions().timeZone
  });

  const joinAction = (access_code) => {
    setSubmitLoader(true);
    joinService(access_code)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.code === 200
        ) {
          setPostData({
            ...postData,
            event_id: response.data.data.event_id,
          });
          setContent(response.data.data.acceptance_content);
          dispatch(setEventIdAction(response.data.data.event_id));
          dispatch(
            setParticipantTypeAction(response.data.data.participant_type)
          );

          if (invalidAccessCode) {
            // console.log("COme here");
            // setTimeout(() => {
            //   window.location.href =
            //     window.location.origin + "/join/" + accessCode;
            // }, 5000);
            setInvalidAccessCode(false);
          }
          // setInvalidAccessCode(false);
        } else {
          setInvalidAccessCode(true);
          setAccessCode(access_code);
          if (
            response &&
            response.data &&
            response.data.errors &&
            response.data.errors.length
          ) {
            toast.error(response.data.errors[0]);
          } else {
            toast.error("Access code not found.");
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("***JOIN API err***", err);
        setLoader(false);
        setInvalidAccessCode(true);
      })
      .finally(() => setSubmitLoader(false));
  };

  useEffect(() => {
    let pathname = window.location.pathname;
    let pathnameArr = pathname.split("/join/");
    let access_code = "";
    if (pathnameArr.length > 1) {
      access_code = pathnameArr[1];
    }
    if (access_code) {
      joinAction(access_code);
    } else {
      setInvalidAccessCode(true);
      setLoader(false);
    }

    localStorage.clear();
    dispatch(setInitialStateAction());
  }, []);

  useEffect(() => {
    if (event_id) {
      getEventDetails(event_id).then((resp) => {
        if (resp && resp.data && resp.data.code === 200 && resp.data.data) {
          setBoothList(resp.data.data.languages);
          setEventDetail(resp.data.data);
        } else {
          setBoothList({});
        }
      });
    }
  }, [event_id]);

  useEffect(() => {
    let firstBooth_language_id = "";
    if (boothList && Object.keys(boothList).length > 0) {
      let sortedLanguages = Object.values(boothList)
        .map((value) => value)
        .sort();
      firstBooth_language_id = Object.keys(boothList).find((key) => {
        return boothList[key] === sortedLanguages[0];
      });
    }

    setPostData({
      ...postData,
      name: displayName,
      audio_in_device: audioInputDeviceId || "default",
      audio_out_device: audioOutputDeviceId || "default",
      camera: videoInputDeviceId || "default",
      booth_language_id: booth_language_id || firstBooth_language_id,
    });
  }, [
    audioInputDeviceId,
    audioOutputDeviceId,
    videoInputDeviceId,
    displayName,
    booth_language_id,
    boothList,
  ]);

  const getErrorList = () => {
    let list = [];
    for (let key in postData) {
      let field = "";
      if (key === "name") {
        field = "Name";
      }
      if (key === "booth_language_id") {
        field = "Booth";
      }
      if (key === "access_code") {
        field = "Access Code";
      }
      if (key === "audio_in_device") {
        field = "Microphone";
      }
      if (key === "audio_out_device") {
        field = "Listen Through";
      }
      if (key === "camera") {
        field = "Camera";
      }
      if (key === "timezone") {
        field = "Timezone";
      }
      if (
        key !== "audio_out_device" &&
        (!postData[key] || postData[key].trim() === "")
      ) {
        list.push(<div>The {field} field is required.</div>);
      }
    }
    if (list.length === 0 && !isAgree && participant_type !== "client") {
      list.push(<div>Please agree to the terms.</div>);
      return list;
    } else {
      return list;
    }
  };

  const accessCodeCheck = () => {
    if (!accessCode) {
      toast.error("Access code Field is Required");
      return false;
    }
    joinAction(accessCode);
  };

  const CloseButton = ({ closeToast }) => (
    <i class="fa-solid fa-xmark" onClick={closeToast}></i>
  );

  const connect = () => {
    setIsError(false);
    for (let key in postData) {
      if (
        participant_type !== "interpreter" &&
        key === "name" &&
        (!postData[key] || postData[key].trim() === "")
      ) {
        setIsError(true);
        return false;
      }
      if (participant_type === "interpreter" && !postData[key]) {
        setIsError(true);
        return false;
      }
      if (
        participant_type === "moderator" &&
        key === "audio_out_device" &&
        !postData[key]
      ) {
        setIsError(true);
        return false;
      }
    }
    if (!isAgree && participant_type !== "client") {
      setIsError(true);
      return false;
    }
    setLoading(true);
    if (participant_type === "interpreter") {
      let payload = {
        ...postData,
        timezone: postData.timezone || eventDetail.timezone,
      };
      interpreterJoinService(payload)
        .then((resp) => {
          // console.log("***resp***", resp);
          if (resp && resp.data && resp.data.code === 200) {
            toast.success("Interpreter joined successfully!", {
              closeButton: CloseButton,
            });
            dispatch(setParticipantIdAction(resp.data.data)); // Save Particpent Id
            dispatch(setEventJoinedAction(true)); // To Show Console
            dispatch(setDisplayNameAction(postData.name)); // To set Display Name
            dispatch(setBoothIdAction(postData.booth_language_id)); // To set Booth Id Name
            dispatch(
              setEnableTextChatAction(
                eventDetail.enable_text_chat === "1" ? true : false
              )
            );
            dispatch(
              setVideoEnabledAction(
                eventDetail.enable_video === "1" ? true : false
              )
            );
            dispatch(
              setAudioEnabledAction(
                eventDetail.enable_audio === "1" ? true : false
              )
            );

            setTimeout(() => window.location.reload(), 1000);
          } else if (
            resp &&
            resp.data &&
            resp.data.code === 400 &&
            resp.data.errors.length
          ) {
            toast.error(resp.data.errors[0]);
          } else {
            toast.error("Something went wrong!");
          }
        })
        .catch((err) => {
          console.log("***ERR***", err);
          toast.error("Something went wrong!");
        })
        .finally(() => setLoading(false));
    }

    if (participant_type === "client") {
      clientJoinService({
        name: postData.name,
        event_id: postData.event_id,
        timezone: postData.timezone || eventDetail.timezone,
      })
        .then((resp) => {
          if (resp && resp.data && resp.data.code === 200) {
            toast.success("Client joined successfully!");
            dispatch(setParticipantIdAction(resp.data.data)); // Save Particpent Id
            dispatch(setEventJoinedAction(true)); // To Show Console
            dispatch(setDisplayNameAction(postData.name)); // To set Display Name
            dispatch(setBoothIdAction(postData.booth_language_id)); // To set Booth Id Name
            setTimeout(() => window.location.reload(), 1000);
          } else if (
            resp &&
            resp.data &&
            resp.data.code === 400 &&
            resp.data.errors.length
          ) {
            toast.error(resp.data.errors[0]);
          } else {
            toast.error("Something went wrong!");
          }
        })
        .catch((err) => {
          console.log("***ERR***", err);
          toast.error("Something went wrong!");
        })
        .finally(() => setLoading(false));
    }

    if (participant_type === "moderator") {
      moderatorJoinService({
        name: postData.name,
        event_id: postData.event_id,
        audio_in_device: postData.audio_in_device,
        timezone: postData.timezone || eventDetail.timezone,
      })
        .then((resp) => {
          console.log("***resp***", resp);
          if (resp && resp.data && resp.data.code === 200) {
            toast.success("Technician joined successfully!");
            dispatch(setParticipantIdAction(resp.data.data)); // Save Particpent Id
            dispatch(setEventJoinedAction(true)); // To Show Console
            dispatch(setDisplayNameAction(postData.name)); // To set Display Name
            dispatch(setBoothIdAction(postData.booth_language_id)); // To set Booth Id Name
            setTimeout(() => window.location.reload(), 500);
          } else if (
            resp &&
            resp.data &&
            resp.data.code === 400 &&
            resp.data.errors.length
          ) {
            toast.error(resp.data.errors[0]);
          } else {
            toast.error("Something went wrong!");
          }
        })
        .catch((err) => {
          console.log("***ERR***", err);
          toast.error("Something went wrong!");
        })
        .finally(() => setLoading(false));
    }
  };

  const onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      accessCodeCheck();
    }
  };

  return (
    <div className="content-wrapper" style={{ minHeight: "237.094px" }}>
      <div className="content-header">
        <div
          className={`container-fluid ${
            participant_type !== "client" ? "" : "w-25 m-auto"
          }`}
        >
          {loader && (
            <div className="card- m-auto text-center" style={{ width: "100%" }}>
              <div className="card-body">
                <CircularProgress />
              </div>
            </div>
          )}

          {!loader && invalidAccessCode && (
            <div className="card m-auto text-center" style={{ width: 325 }}>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="code" className="control-label">
                    Access Code
                  </label>
                  <input
                    type="text"
                    name="access_code"
                    value={accessCode}
                    maxLength="8"
                    placeholder="Enter code here"
                    className="text-center form-control bg-light border border-dark"
                    onChange={(e) => {
                      setAccessCode(e.target.value);
                    }}
                    onKeyDown={onKeyDown}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <input
                    onClick={() => accessCodeCheck()}
                    type="submit"
                    name="submit"
                    defaultValue={submitLoader ? "Loading..." : "Next"}
                    className={`btn btn-primary ${
                      submitLoader ? "disabled" : ""
                    }`}
                  />
                </div>
                <div className="form-group">
                  <p>
                    To login into InterStar ICP{" "}
                    <a href="https://rsi-now.com" className="link-primary">
                      click here
                    </a>
                  </p>
                </div>
              </div>
            </div>
          )}

          {!loader && !invalidAccessCode && (
            <div className="card">
              <div className="card-body">
                {isError && getErrorList().length > 0 && (
                  <div className="alert alert-danger alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      onClick={() => setIsError(false)}
                    >
                      ×
                    </button>
                    <div className="d-flex justify-content-start align-items-center">
                      <i className="fa fa-info-circle mr-2"></i>
                      <div className="flex-nowrap">{getErrorList()}</div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div
                    className={`${
                      participant_type !== "client"
                        ? "offset-1 col-4"
                        : "col-12"
                    } `}
                  >
                    <h3>{eventDetail.name}</h3>
                    <PreJoinedControll
                      boothList={boothList}
                      submit={() => connect()}
                    />
                    {participant_type === "client" && (
                      <div className="form-group">
                        <button
                          type="button"
                          onClick={() => connect()}
                          className={`btn btn-success ${
                            loading ? "disabled" : ""
                          }`}
                        >
                          {!loading ? "Connect" : "Loading..."}
                        </button>
                      </div>
                    )}
                  </div>
                  {participant_type !== "client" && (
                    <div className="col-6 pt-4">
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                      <div className="form-check text-center">
                        <input
                          type="checkbox"
                          name="agree"
                          className="form-check-input"
                          onChange={() => {
                            setIsAgree(!isAgree);
                          }}
                        />

                        <label className="form-check-label">I agree</label>
                      </div>
                      {/* <hr /> */}
                      <div className="text-center pt-4">
                        <button
                          type="button"
                          onClick={() => connect()}
                          className={`btn btn-success ${
                            loading ? "disabled" : ""
                          }`}
                        >
                          {!loading ? "Connect" : "Loading..."}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
