import React from "react";
import Configure from "../components/Dialog/Configure";
const packageJson = require("../../package.json");

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="main-footer">
        {/* <div className="float-right d-none d-sm-inline">
                </div> */}
        © 2020-{new Date().getFullYear()} InterStar Translations
        <div className="">Version {packageJson.version}</div>
      </footer>
      <div
        className="modal fade"
        id="imageModal"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        aria-modal="true"
        style={{ display: "none" }}
      >
        <div
          className="modal-dialog modal-dialog-scrollable modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              {/* <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1> */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div>
                  <img
                    onClick={(event) => {
                      window.open(event.target.src);
                    }}
                    src={""}
                    alt=""
                    id="chatImageFile"
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                  />
                  <div className="mt-3 text-muted font-italic">
                    Click this image to open it in a new tab
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
      <Configure />
    </React.Fragment>
  );
};

export default Footer;
