import axios from "axios";
import {API_BASE_URL} from "./constant";

axios.interceptors.request.use(
    function(config) {
        config.url = API_BASE_URL + '/api/' + config.url;
        config.headers["token"] = 'abc123';
      return config;
    }, 
    function(error) {
      return Promise.reject(error);
    }
);

export default axios;