import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { changeAttackValueAction, changeDefaultBrickLimiterAction, changePostgainValueAction, changePregainValueAction, changeReleaseValueAction, changeThresholdValueAction } from "../../Redux/Reducers/Connection";
import { toast } from "react-toastify";
import { getEventDetails } from "../../Services/interceptorServices";

const Configure = () => {
    const { event_id, thresholdValue, releaseValue, attackValue, pregainValue, postgainValue, defaultBrickLimiter } = useSelector((state) => state.connection);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const thresholdValueChange = (e) => {
        dispatch(changeThresholdValueAction(e.target.value));
    }

    const attackValueChange = (e) => {
        dispatch(changeAttackValueAction(e.target.value));
    }

    const releaseValueChange = (e) => {
        dispatch(changeReleaseValueAction(e.target.value));
    }

    const pregainValueChange = (e) => {
        dispatch(changePregainValueAction(e.target.value));
    }

    const postgainValueChange = (e) => {
        dispatch(changePostgainValueAction(e.target.value));
    }
    const saveToDefault = (e) => {

        toast.success("Your current limiter settings saved to default");
    }
    const resetData = async (e) => {
        setLoading(true);
        let resp = await getEventDetails(event_id);
        if (resp && resp.data && resp.data.code === 200 && resp.data.data) {
            let newDefaultSettings = {
                thresholdValue: resp.data.data.default_threshold,
                releaseValue: resp.data.data.default_release_seconds,
                attackValue: resp.data.data.default_attack_seconds,
                pregainValue: resp.data.data.default_pregain_db,
                postgainValue: resp.data.data.default_postgain_db
            }
            dispatch(changeDefaultBrickLimiterAction(JSON.stringify(newDefaultSettings)));
            dispatch(changeThresholdValueAction(newDefaultSettings.thresholdValue));
            dispatch(changeAttackValueAction(newDefaultSettings.attackValue));
            dispatch(changeReleaseValueAction(newDefaultSettings.releaseValue));
            dispatch(changePregainValueAction(newDefaultSettings.pregainValue));
            dispatch(changePostgainValueAction(newDefaultSettings.postgainValue));


        } else {
            dispatch(changeThresholdValueAction("-18"));
            dispatch(changeAttackValueAction("0.004"));
            dispatch(changeReleaseValueAction("0.004"));
            dispatch(changePregainValueAction("2"));
            dispatch(changePostgainValueAction("8"));
        }
        setLoading(false);
        toast.success("Your limiter settings have been reset to default");

    }

    return (
        <>

            <div className="modal fade" id="configureModal" tabIndex="-1" aria-labelledby="exampleModal1Label" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5>Brickwall settings</h5>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <h6 className="modal-title" id="exampleModal1Label">Please change these parameters only if you are familiar with how limiters work!. If your headset has consistently low volume at its maximum volume setting, you may increase postgain below in small steps as needed however if you change to a different headset that setting may be too high.</h6>
                            <div className="row mt-3">
                                <div className="col col-2 ms-5">
                                    <p className="fs-6">Threshold:</p>
                                </div>
                                <div className="col-4">
                                    <select value={thresholdValue} className="form-select form-select-sm mb-3" aria-label=".form-select-sm example" onChange={thresholdValueChange}>
                                        <option value="0">0 dB</option>
                                        <option value="-2">-2 dB</option>
                                        <option value="-4">-4 db</option>
                                        <option value="-6">-6 db</option>
                                        <option value="-8">-8 db</option>
                                        <option value="-10">-10 db</option>
                                        <option value="-12">-12 db</option>
                                        <option value="-14">-14 db</option>
                                        <option value="-16">-16 db</option>
                                        <option value="-18">-18 dB</option>
                                        <option value="-20">-20 dB</option>
                                        <option value="-22">-22 dB</option>
                                        <option value="-24">-24 dB</option>
                                        <option value="-26">-26 dB</option>
                                        <option value="-28">-28 dB</option>
                                        <option value="-30">-30 dB</option>
                                        <option value="-32">-32 dB</option>
                                        <option value="-34">-34 dB</option>
                                        <option value="-36">-36 dB</option>
                                        <option value="-38">-38 dB</option>
                                        <option value="-40">-40 dB</option>

                                    </select>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-2 ms-5">
                                    <p className="fs-6">Attack:</p>
                                </div>
                                <div className="col-4">
                                    <select value={attackValue} className="form-select form-select-sm mb-3" aria-label=".form-select-sm example" onChange={attackValueChange}>
                                        <option value="2.000">2 sec</option>
                                        <option value="1.000">1 sec</option>
                                        <option value="0.500">.5 sec</option>
                                        <option value="0.050">.05 sec</option>
                                        <option value="0.005">.005 sec</option>
                                        <option value="0.004">.004 sec</option>
                                        <option value="0.003">.003 sec</option>
                                        <option value="0.002">.002 sec</option>
                                        <option value="0.001">.001 sec</option>
                                        <option value="0.000">0 sec</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-2 ms-5">
                                    <p className="fs-6">Release:</p>
                                </div>
                                <div className="col-4">
                                    <select value={releaseValue} className="form-select form-select-sm mb-3" aria-label=".form-select-sm example" onChange={releaseValueChange}>
                                        <option value="5.000">5 sec</option>
                                        <option value="4.000">4 sec</option>
                                        <option value="3.000">3 sec</option>
                                        <option value="2.000">2 sec</option>
                                        <option value="1.000">1 sec</option>
                                        <option value="0.500">.5 sec</option>
                                        <option value="0.050">.05 sec</option>
                                        <option value="0.005">.005 sec</option>
                                        <option value="0.004">.004 sec</option>
                                        <option value="0.003">.003 sec</option>
                                        <option value="0.002">.002 sec</option>
                                        <option value="0.001">.001 sec</option>
                                        <option value="0.000">0 sec</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-2 ms-5">
                                    <p className="fs-6">Pregain:</p>
                                </div>
                                <div className="col-4">
                                    <select value={pregainValue} className="form-select form-select-sm mb-3" aria-label=".form-select-sm example" onChange={pregainValueChange}>
                                        <option value="10">+10 dB</option>
                                        <option value="9">+9 dB</option>
                                        <option value="8">+8 dB</option>
                                        <option value="7">+7 dB</option>
                                        <option value="6">+6 dB</option>
                                        <option value="5">+5 dB</option>
                                        <option value="4">+4 dB</option>
                                        <option value="3">+3 dB</option>
                                        <option value="2">+2 dB</option>
                                        <option value="1">+1 dB</option>
                                        <option value="0">0 dB</option>
                                        <option value="-1">-1 dB</option>
                                        <option value="-2">-2 dB</option>
                                        <option value="-3">-3 dB</option>
                                        <option value="-4">-4 dB</option>
                                        <option value="-5">-5 dB</option>
                                        <option value="-6">-6 dB</option>
                                        <option value="-7">-7 dB</option>
                                        <option value="-8">-8 dB</option>
                                        <option value="-9">-9 dB</option>
                                        <option value="-10">-10 dB</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col col-2 ms-5">
                                    <p className="fs-6">Postgain:</p>
                                </div>
                                <div className="col-4">
                                    <select value={postgainValue} className="form-select form-select-sm mb-3" aria-label=".form-select-sm example" onChange={postgainValueChange}>
                                        <option value="20">+20 dB</option>
                                        <option value="19">+19 dB</option>
                                        <option value="18">+18 dB</option>
                                        <option value="17">+17 dB</option>
                                        <option value="16">+16 dB</option>
                                        <option value="15">+15 dB</option>
                                        <option value="14">+14 dB</option>
                                        <option value="13">+13 dB</option>
                                        <option value="12">+12 dB</option>
                                        <option value="11">+11 dB</option>
                                        <option value="10">+10 dB</option>
                                        <option value="9">+9 dB</option>
                                        <option value="8">+8 dB</option>
                                        <option value="7">+7 dB</option>
                                        <option value="6">+6 dB</option>
                                        <option value="5">+5 dB</option>
                                        <option value="4">+4 dB</option>
                                        <option value="3">+3 dB</option>
                                        <option value="2">+2 dB</option>
                                        <option value="1">+1 dB</option>
                                        <option value="0">0 dB</option>
                                        <option value="-1">-1 dB</option>
                                        <option value="-2">-2 dB</option>
                                        <option value="-3">-3 dB</option>
                                        <option value="-4">-4 dB</option>
                                        <option value="-5">-5 dB</option>
                                        <option value="-6">-6 dB</option>
                                        <option value="-7">-7 dB</option>
                                        <option value="-8">-8 dB</option>
                                        <option value="-9">-9 dB</option>
                                        <option value="-10">-10 dB</option>
                                        <option value="-11">-11 dB</option>
                                        <option value="-12">-12 dB</option>
                                        <option value="-13">-13 dB</option>
                                        <option value="-14">-14 dB</option>
                                        <option value="-15">-15 dB</option>
                                        <option value="-16">-16 dB</option>
                                        <option value="-17">-17 dB</option>
                                        <option value="-18">-18 dB</option>
                                        <option value="-19">-19 dB</option>
                                        <option value="-20">-20 dB</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success button-position" onClick={saveToDefault}>Save as Custom Default</button>
                            <button type="button" data-bs-dismiss="modal--" aria-label="Close" className="btn btn-danger" onClick={resetData}>{loading === false ? "Reset to System Default" : "Loading..."}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Configure;