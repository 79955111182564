import React, { Fragment, memo, useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelecteduserAction } from "../Redux/Reducers/Connection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import LocalTrackStats from "./localTrackStats";

function LocalTrack({
  track,
  index,
  isAudioMuted,
  chimeEnabled,
  boothmatesMute,
  JitsiMeetJS,
  room,
}) {
  const dispatch = useDispatch();
  const { videoEnabled } = useSelector((state) => state.connection);
  const selectUserForLargeVideo = useCallback(() => {
    dispatch(setSelecteduserAction("local"));
  }, [dispatch, setSelecteduserAction]);


  const attachVideo = useCallback(
    (refData) => {
      if (refData) {
        track.attach(refData);
      }
    },
    [track]
  );

  if (track.getType() === "video") {
    if (!videoEnabled) {
      return (
        <React.Fragment>
          <LazyLoadImage
            key={index}
            className="video-thumb img-fluid noImage-thumb"
            id={`localVideo${index}`}
            alt="video"
            src="/media/noPicture.svg"
            effect="blur"
          />
          {/* <LocalTrackStats JitsiMeetJS={JitsiMeetJS} room={room} /> */}
          <div className="video-controls">
            <i
              className={`${
                chimeEnabled
                  ? "fa-solid fa-bell-on"
                  : "fa-solid fa-bell-slash text-danger"
              }`}
            ></i>
            <i
              className={` ${
                boothmatesMute
                  ? "fa-solid fa-ear-deaf text-danger"
                  : "fa-solid fa-ear "
              }`}
            ></i>
            <i
              className={` ${
                isAudioMuted == undefined || isAudioMuted
                  ? "fa-solid fa-microphone-slash"
                  : "fa-solid fa-microphone text-danger"
              }`}
            ></i>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <video
          key={index}
          onClick={selectUserForLargeVideo}
          className="video-thumb img-fluid"
          autoPlay="1"
          ref={attachVideo}
          id={`localVideo${index}`}
          poster={`/media/black-screen.svg`}
        />
        {/* <LocalTrackStats JitsiMeetJS={JitsiMeetJS} room={room} /> */}
        <div className="video-controls">
          <i
            className={`${
              chimeEnabled
                ? "fa-solid fa-bell-on"
                : "fa-solid fa-bell-slash text-danger"
            }`}
          ></i>
          <i
            className={` ${
              boothmatesMute
                ? "fa-solid fa-ear-deaf text-danger"
                : "fa-solid fa-ear "
            }`}
          ></i>
          <i
            className={` ${
              isAudioMuted == undefined || isAudioMuted
                ? "fa-solid fa-microphone-slash"
                : "fa-solid fa-microphone text-danger"
            }`}
          ></i>
        </div>
      </React.Fragment>
    );
  }
  return <Fragment key={index}></Fragment>;
}

function compare(preProps, nextProps) {
  return preProps.track.isMuted() !== nextProps.track.isMuted();
}

export default memo(LocalTrack, compare);
