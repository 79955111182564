/* global JitsiMeetJS */
// @flow

import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import JoinedParticipantControll from "../components/JoinedParticipantControll";
import ParticipantsSideListing from "../components/ParticipantsSideListing";
import RemoteParticipantsJoinedListing from "../components/RemoteParticipantsJoinedListing";
import MainAudioControl from "../components/MainAudioControl";
import MuteControl from "../components/MuteControl";
import Chat from "../components/Chat";
import {
  changeChimeEnabledAction,
  setConnectionAction,
  changeBrickLimiterAction,
  setParticipantIdAction,
  setEventJoinedAction,
  setDisplayNameAction,
  setInitialStateAction,
  changeChimeVolume,
} from "../Redux/Reducers/Connection";
import QuickMessages from "../components/QuickMessages";
import InterpreterListing from "../components/InterpreterListing";
import { toast } from "react-toastify";
import {
  interpreterExitService,
  clientExitService,
  moderatorExitService,
} from "../Services/interceptorServices";

let intervalId;

const ConferenceWrapper = ({
  connection,
  videoInputDeviceId,
  audioInputDeviceId,
  audioOutputDeviceId,
}) => {
  const {
    event_id,
    booth_language_id,
    audioEnabled,
    displayName,
    participant_type,
    participantId,
    brickLimiter,
    chimeEnabled,
    boothmateVolume,
    audioOutputDeviceId: audioOutputDeviceIdState,
    showSettings,
    showQuickMessages,
    chimeVolume,
  } = useSelector((state) => state.connection);

  const dispatch = useDispatch();
  const [eventDetail, setEventDetail] = useState({
    enable_video: "0",
    enable_audio: "0",
  });

  const [participantData, setParticipantData] = useState([]);
  const [oParticipantData, setOParticipantData] = useState([]);
  const [boothName, setBoothName] = useState("");
  const [displayRemainingTime, setDisplayRemainingTime] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [minutes, setMinutes] = useState(30);
  const [timerString, setTimerString] = useState("Loading...");
  const [showStartTime, setShowStartTime] = useState(true);
  const [timerClass, setTimerClass] = useState("text-success");
  const audioEl = useRef(null);
  const [isChimeVolumeView, setChimeVolumeView] = useState(false);
  const [isApiCalled, setApiCalled] = useState(false);
  const [eventLockData, setEventLockData] = useState("");

  let room = window.APP?.room;

  useEffect(() => {
    let savedMinutes = localStorage.getItem("selectedMinutes");
    if (savedMinutes) {
      setMinutes(savedMinutes);
      countdownTimeStart(savedMinutes);
    } else {
      setTimerString("30:00");
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const exitSettings = (resp, access_code) => {
    if (resp && resp.data && resp.data.code === 200) {
      dispatch(setParticipantIdAction(0));
      dispatch(setEventJoinedAction(false));
      dispatch(setDisplayNameAction(""));
      localStorage.clear();
      dispatch(setInitialStateAction());
      setTimeout(() => {
        window.location.href = "/join/" + access_code;
      }, [100]);
    }
  };

  useEffect(() => {
    if (eventDetail) {
      let client_code = eventDetail?.client_code;
      let interpreter_code = eventDetail?.interpreter_code;
      let moderator_code = eventDetail?.moderator_code;

      let pathname = window.location.pathname;
      let pathnameArr = pathname.split("/join/");
      let access_code = "";
      if (pathnameArr.length > 1) {
        access_code = pathnameArr[1];
        let exitConsole = false;
        if (
          moderator_code &&
          participant_type === "moderator" &&
          access_code !== moderator_code
        ) {
          toast.error("Invalid access code");
          setTimeout(() => {
            exitConsole = true;
            moderatorExitService({
              participant_id: participantId,
              is_connected: 0,
            }).then((resp) => {
              exitSettings(resp, access_code);
            });
          }, 1000);
        }

        if (
          interpreter_code &&
          participant_type === "interpreter" &&
          access_code !== interpreter_code
        ) {
          toast.error("Invalid access code");
          setTimeout(() => {
            exitConsole = true;
            interpreterExitService({
              participant_id: participantId,
              is_connected: 0,
            }).then((resp) => {
              exitSettings(resp, access_code);
            });
          }, 1000);
        }
        if (
          client_code &&
          participant_type === "client" &&
          access_code !== client_code
        ) {
          toast.error("Invalid access code");
          setTimeout(() => {
            exitConsole = true;
            clientExitService({
              participant_id: participantId,
              is_connected: 0,
            }).then((resp) => {
              exitSettings(resp, access_code);
            });
          }, 1000);
        }
        if (exitConsole) {
          return false;
        }
      }
      let timerId;

      try {
        let edd = eventDetail.duration;

        let durationString = "";
        let hs = "";
        if (edd) {
          hs = edd[edd.length - 1];
          for (let ch in edd) {
            if (!isNaN(edd[ch])) {
              durationString += edd[ch];
            }
          }
        }

        let duration = 60 * Number(durationString);
        if (hs == "h") {
          duration = 60 * Number(durationString) * 60;
        }

        let timer = duration,
          minute,
          seconds;
        timerId = setInterval(function () {
          minute = parseInt(timer / 60, 10);
          seconds = parseInt(timer % 60, 10);

          minute = minute < 10 ? "0" + minute : minute;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          setDisplayRemainingTime(minute + ":" + seconds);

          if (--timer < 0) {
            timer = duration;
          }
        }, 1000);
      } catch (err) {
        console.log("err->", err);
      }

      return () => {
        clearInterval(timerId);
      };
    }
  }, [eventDetail]);

  useEffect(() => {
    if (connection) {
      let meetingId = `${process.env.REACT_APP_JITSI_ROOM}-${event_id}-${booth_language_id}`;
      dispatch(
        setConnectionAction({
          meetingId,
          connection,
          displayName,
          videoInputDeviceId,
          audioInputDeviceId,
          audioOutputDeviceId,
          participant_type,
        })
      );
    }
  }, [connection]);

  useEffect(() => {
    if (eventDetail && eventDetail.languages) {
      setBoothName(eventDetail.languages[booth_language_id]);
    }
  }, [booth_language_id, eventDetail]);

  useEffect(() => {
    if (audioEl && audioEl.current.setSinkId) {
      audioEl.current.setSinkId(audioOutputDeviceIdState);
    }
  }, [audioEl, audioOutputDeviceIdState]);

  const handleChange = (event) => {
    setMinutes(event.target.value);
    setTimerClass("text-success");
    setTimerString(event.target.value + ":00");
  };

  const playChimeCopy = (isTosterShow = false) => {
    if (chimeEnabled === true) {
      if (audioEl && audioEl.current) {
        // let audioVolume = ((boothmateVolume || 1) * 30 / 100);
        // audioEl.current.volume = audioVolume / 100;
        audioEl.current.volume = chimeVolume;
        audioEl.current.play();
      }
    } else {
      if (isTosterShow) {
        toast.warning(
          'You have disabled chimes. Click "Chimes Disabled" button to enable chimes.'
        );
      }
    }
  };

  const playChime = useCallback(
    (isTosterShow = false) => {
      if (chimeEnabled === true) {
        if (audioEl && audioEl.current) {
          // let audioVolume = ((boothmateVolume || 1) * 30 / 100);
          // audioEl.current.volume = audioVolume / 100;
          audioEl.current.volume = chimeVolume;
          audioEl.current.play();
        }
      } else {
        if (isTosterShow) {
          toast.warning(
            'You have disabled chimes. Click "Chimes Disabled" button to enable chimes.'
          );
        }
      }
    },
    [chimeEnabled, audioEl, boothmateVolume, chimeVolume]
  );

  const countdownTimeStart = useCallback(
    (selectedMinutes = 0) => {
      if (intervalId) {
        return false;
      }
      setTimerClass("text-success");

      setShowStartTime(false);
      let savedCountDownDate = localStorage.getItem("currentTime");
      let countDownDateTime = new Date();

      let currentTimezone = localStorage.getItem("currentTimezone");
      countDownDateTime.setMinutes(
        Number(countDownDateTime.getMinutes()) +
          parseInt(selectedMinutes || minutes)
      );
      let countDownDate =
        savedCountDownDate ||
        Number(countDownDateTime.getTime()) + currentTimezone * 60 * 1000;
      localStorage.setItem("currentTime", countDownDate);
      localStorage.setItem("selectedMinutes", selectedMinutes || minutes);
      const room = window.APP?.room;
      if (room && selectedMinutes === 0) {
        room.setLocalParticipantProperty("timerValue", 0);
        room.setLocalParticipantProperty("timerStartTime", 0);
        room.setLocalParticipantProperty("currentTimezone", 0);
        room.setLocalParticipantProperty("currentTimezone", currentTimezone);
        room.setLocalParticipantProperty("timerStartTime", countDownDate);
        room.setLocalParticipantProperty("timerValue", minutes);
      }

      // Update the count down every 1 second
      intervalId = setInterval(() => {
        // Get todays date and time
        let currentTimezoneValue = localStorage.getItem("currentTimezone");
        let now = new Date().getTime() + currentTimezoneValue * 60 * 1000;

        // Find the distance between now an the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minute and seconds
        let minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if (minute < 10) {
          minute = "0" + minute;
        }
        if (minute < 1) {
          setTimerClass("text-warning");
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }

        // Output the result in an element with id="demo"
        setTimerString(minute + ":" + seconds);

        // If the count down is over, write some text
        if (distance < 0 || (minute < 1 && seconds < 1)) {
          clearInterval(intervalId);
          intervalId = "";
          localStorage.removeItem("currentTime");
          localStorage.removeItem("selectedMinutes");

          setTimerString("00:00");
          setTimerClass("text-danger");
          setShowStartTime(true);
        }
      }, 1000);
    },
    [minutes]
  );

  const resetTimer = useCallback(() => {
    localStorage.removeItem("currentTime");
    localStorage.removeItem("selectedMinutes");
    setTimerString(minutes + ":00");
    clearInterval(intervalId);
    intervalId = "";
    setShowStartTime(true);
    setTimerClass("text-success");
  }, [minutes]);

  useEffect(() => {
    if (room) {
      room.on(
        JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
        (prop1) => {
          console.log("***PARTICIPANT_PROPERTY_CHANGED", prop1._properties);

          if (prop1._properties?.isEventLock) {
            setEventLockData(prop1._properties?.isEventLock);
          }

          if (prop1._properties.timerValue == -1) {
            resetTimer();
          } else if (prop1._properties.timerValue) {
            if (prop1._properties.timerStartTime) {
              localStorage.setItem(
                "currentTime",
                prop1._properties.timerStartTime
              );
            }
            if (prop1._properties.currentTimezone) {
              localStorage.setItem(
                "currentTimezone",
                prop1._properties.currentTimezone
              );
            }
            setMinutes(prop1._properties.timerValue);
            if (!intervalId) {
              countdownTimeStart(prop1._properties.timerValue);
            }
          }
        }
      );
    }
  }, [room, resetTimer]);

  const callBackListner = (prop1) => {
    if (prop1._properties.playChime == 1) {
      playChimeCopy();
    }
  };

  useEffect(() => {
    if (room) {
      room.on(
        JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
        callBackListner
      );
    }

    return () => {
      if (room) {
        room.off(
          JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
          callBackListner
        );
      }
    };
  });

  useEffect(() => {
    if (timerString === "00:58" && minutes !== "1") {
      playChimeCopy();
    }
  }, [timerString]);

  let minuteProps = {};
  if (!showStartTime) {
    minuteProps.disabled = "disabled";
  }
  const chimeEnabledAction = () => {
    dispatch(changeChimeEnabledAction(chimeEnabled === true ? false : true));
  };

  useEffect(() => {
    if (room && chimeEnabled === true) {
      room.setLocalParticipantProperty("chimeEnabled", true);
    } else if (room && chimeEnabled === false) {
      room.setLocalParticipantProperty("chimeEnabled", false);
    }
  }, [room, chimeEnabled]);

  const changeBrickState = () => {
    if (brickLimiter === true) {
      if (
        window.confirm(
          "You are disabling a system that may protect your hearing. Proceed? Yes/No"
        )
      ) {
        dispatch(changeBrickLimiterAction(false));
      }
    } else {
      dispatch(changeBrickLimiterAction(true));
    }
  };
  let brickLimiterSwitchProps = {};
  if (brickLimiter === true) {
    brickLimiterSwitchProps.checked = true;
  }
  let configureSwitchProps = {};
  if (brickLimiter === false) {
    configureSwitchProps.disabled = true;
  }

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            {eventDetail &&
              !!eventDetail.demo_banner_content &&
              eventDetail.is_demo && (
                <div
                  className="alert alert-danger alert-dismissible"
                  dangerouslySetInnerHTML={{
                    __html: eventDetail.demo_banner_content,
                  }}
                />
              )}
            {eventDetail && eventDetail.is_demo && (
              <div className="alert alert-danger alert-dismissible">
                The ICP demo allows you and others to access an event as
                technicians, interpreters, or clients for up to 24 hours. Total
                connection time is limited to {eventDetail.duration}, Time Left
                : {displayRemainingTime}
              </div>
            )}
            <div className="row">
              <div className="col-12 col-md-9">
                <div className="row">
                  <div
                    className={`col-12 rightsidebar ${
                      participant_type === "moderator"
                        ? " col-md-4 "
                        : "col-md-4"
                    }`}
                  >
                    {/* <div className="alert alert-success alert-dismissible">
                                            <button type="button" className="close" data-dismiss="alert" aria-hidden="true" onClick={() => { }}>×</button>
                                            Welcome! Your participant name is <b>{displayName}</b>.
                                        </div> */}
                    {participant_type !== "moderator" &&
                      eventDetail &&
                      !!eventDetail.event_notice && (
                        <div className="alert alert-info alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                            onClick={() => {}}
                          >
                            ×
                          </button>
                          {eventDetail.event_notice}
                        </div>
                      )}
                    {participant_type === "interpreter" &&
                      isApiCalled &&
                      eventDetail &&
                      (eventDetail.enable_video != "1" ||
                        eventDetail.enable_audio != "1") && (
                        <div className="alert alert-info alert-dismissible col-12 text-dark">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                            onClick={() => {}}
                          >
                            ×
                          </button>
                          {eventDetail.enable_audio != "1" && "Audio"}
                          {eventDetail.enable_video != "1" &&
                            eventDetail.enable_audio != "1" &&
                            "/"}
                          {eventDetail.enable_video != "1" && "Video"}
                          &nbsp;is disabled for this event
                        </div>
                      )}

                    <ParticipantsSideListing
                      refresh={refresh}
                      getEventDetail={(eventData) => {
                        setEventDetail(eventData);
                      }}
                      setApiCalled={setApiCalled}
                      getCurrentBoothInterpreters={(pData) => {
                        setParticipantData(pData);
                      }}
                      getOtherBoothInterpreters={(pData) => {
                        setOParticipantData(pData);
                      }}
                      eventLockData={eventLockData}
                    />
                    {showSettings && (
                      <JoinedParticipantControll
                        refresh={() => {
                          setRefresh(Number(refresh) + 1);
                        }}
                      />
                    )}
                  </div>

                  {participant_type === "moderator" && (
                    <div className="col-12 col-md-8">
                      {eventDetail && !!eventDetail.event_notice && (
                        <div className="alert alert-info alert-dismissible">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                            onClick={() => {}}
                          >
                            ×
                          </button>
                          {eventDetail.event_notice}
                        </div>
                      )}
                      <InterpreterListing
                        eventDetail={eventDetail}
                        participantData={participantData}
                        oParticipantData={oParticipantData}
                        refresh={() => {
                          setRefresh(Number(refresh) + 1);
                        }}
                        room={room}
                      />
                    </div>
                  )}

                  <div
                    className={`pr-md-0 col-12 ${
                      participant_type === "moderator" ? "col-md-6" : "col-md-8"
                    }`}
                  >
                    {participant_type === "interpreter" && (
                      <div className="card">
                        <div
                          className={`card-body px-2 booth-details ${
                            isChimeVolumeView ? "open-vol-booth" : ""
                          }`}
                        >
                          <div className="booth-detail-wrapper">
                            <div className="booth-detail-left">
                              <h3>
                                <span id="booth-name">{boothName}</span> Booth
                              </h3>
                              <div className="left-buttons">
                                <div className="left-btn-bx">
                                  <div className="left-btn-bx-inner">
                                    <div className="left-buttons-box">
                                      <select
                                        className="custom-select custom-select"
                                        {...minuteProps}
                                        value={minutes}
                                        onChange={handleChange}
                                      >
                                        <option value="30">30m</option>
                                        <option value="20">20m</option>
                                        <option value="15">15m</option>
                                        <option value="10">10m</option>
                                        <option value="5">5m</option>
                                        <option value="1">1m</option>
                                      </select>
                                    </div>
                                    <div className="left-buttons-box">
                                      <input
                                        id="timer"
                                        type="text"
                                        value={timerString}
                                        className={`form-control form-control-sm ${timerClass} text-center`}
                                        style={{ width: "75px" }}
                                        readOnly={true}
                                      />
                                    </div>
                                    {showStartTime && (
                                      <div className="left-buttons-box">
                                        <button
                                          className="btn btn-sm btn-outline-primary"
                                          onClick={() => {
                                            setTimerString(minutes - 1 + ":59");
                                            localStorage.setItem(
                                              "currentTimezone",
                                              new Date().getTimezoneOffset()
                                            );
                                            countdownTimeStart();
                                          }}
                                        >
                                          Start Timer
                                        </button>
                                      </div>
                                    )}
                                    {!showStartTime && (
                                      <div className="left-buttons-box">
                                        <button
                                          className="btn btn-sm btn-outline-danger"
                                          onClick={() => {
                                            const room = window.APP?.room;
                                            if (room) {
                                              room.setLocalParticipantProperty(
                                                "playChime",
                                                0
                                              );
                                              room.setLocalParticipantProperty(
                                                "timerValue",
                                                0
                                              );
                                              room.setLocalParticipantProperty(
                                                "timerStartTime",
                                                0
                                              );
                                              room.setLocalParticipantProperty(
                                                "currentTimezone",
                                                0
                                              );
                                              room.setLocalParticipantProperty(
                                                "timerValue",
                                                -1
                                              );
                                            }
                                            resetTimer();
                                          }}
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                  <label>Shift Timer</label>
                                </div>

                                <div className="left-buttons-box">
                                  {/* <div className='chime-controls-box'> */}

                                  <button
                                    className="btn btn-sm btn-outline-primary br-unset"
                                    onClick={() => {
                                      playChime(true);
                                      if (chimeEnabled) {
                                        room.setLocalParticipantProperty(
                                          "playChime",
                                          1
                                        );
                                        room.setLocalParticipantProperty(
                                          "playChime",
                                          0
                                        );
                                      }
                                    }}
                                  >
                                    Play Chime
                                  </button>
                                </div>
                                {/* <div className='chine-div-line'></div> */}
                                <div className="left-buttons-box">
                                  <button
                                    className={`btn btn-sm btn-outline-primary bl-unset ${
                                      isChimeVolumeView ? "vol-show" : ""
                                    }`}
                                    onClick={() =>
                                      setChimeVolumeView(!isChimeVolumeView)
                                    }
                                  >
                                    Chime Volume
                                  </button>
                                </div>
                                <div className="chime-volume">
                                  {isChimeVolumeView ? (
                                    <>
                                      <div id="player">
                                        <input
                                          type="range"
                                          min={0.1}
                                          max={1}
                                          step={0.1}
                                          defaultValue={chimeVolume}
                                          // onChange={event => {
                                          //     console.log(event.target.value)
                                          // }}
                                          onMouseUp={(e) =>
                                            dispatch(
                                              changeChimeVolume(e.target.value)
                                            )
                                          }
                                          onTouchEnd={(e) =>
                                            dispatch(
                                              changeChimeVolume(e.target.value)
                                            )
                                          }
                                        />
                                      </div>
                                      <div>Chime Volume</div>
                                    </>
                                  ) : null}
                                </div>
                                {/* </div> */}

                                <div className="left-buttons-box">
                                  <button
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={() => {
                                      chimeEnabledAction();
                                    }}
                                  >
                                    {chimeEnabled
                                      ? "Chimes Enabled"
                                      : "Chimes Disabled"}
                                  </button>
                                </div>
                                <div className="clearfix">
                                  <div className="mt-5- float-left">
                                    <div className="form-check form-switch mt-2 pe-auto ">
                                      <input
                                        className="form-check-input cursor mr-1-"
                                        onChange={() => {
                                          changeBrickState();
                                        }}
                                        type="checkbox"
                                        role="switch"
                                        {...brickLimiterSwitchProps}
                                        id="brickwallSettings"
                                      />
                                      <label
                                        className="form-check-label mx-2- text-center mt-1- "
                                        htmlFor="brickwallSettings"
                                      >
                                        Brickwall Audio Limiter ON/OFF
                                      </label>
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    {...configureSwitchProps}
                                    className={`mt-5- btn-sm ms-3 float-left ${
                                      configureSwitchProps.disabled === true
                                        ? "btn btn-secondary"
                                        : "btn btn-primary"
                                    }`}
                                    data-bs-toggle="modal"
                                    data-bs-target="#configureModal"
                                  >
                                    Configure Limiter
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="booth-detail-right">
                              <MuteControl eventDetail={eventDetail} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {participant_type === "interpreter" && (
                      <RemoteParticipantsJoinedListing
                        JitsiMeetJS={JitsiMeetJS}
                        room={room}
                      />
                    )}
                    {participant_type !== "moderator" && showQuickMessages && (
                      <QuickMessages playChimeCopy={playChimeCopy} />
                    )}
                    {/* {participant_type === "moderator" && (
                      <JoinedParticipantControll
                        refresh={() => {
                          setRefresh(Number(refresh) + 1);
                        }}
                      />
                    )} */}

                    {participant_type === "interpreter" && audioEnabled && (
                      <MainAudioControl />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-3">
                <Chat
                  eventDetail={eventDetail}
                  callAlert={playChime}
                  chimeEnabled={chimeEnabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <audio
        ref={audioEl}
        src={`${process.env.PUBLIC_URL}/media/gentle-alarm.mp3`}
      />
    </div>
  );
};

export default ConferenceWrapper;
