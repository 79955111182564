import axios from "../helpers/axios";
import queryString from "query-string";

/**
 *
 * @param {*} eventId
 * @returns List  all participants
 */
export const getParticipantsService = async (eventId) => {
  return await axios.get(`participants/${eventId}`);
};
/**
 *
 * @param {*} eventId
 * @returns Event Details
 */
export const getEventDetails = async (eventId = 5) => {
  return await axios.get(`event/${eventId}`);
};

/**
 *
 * @param {*} eventId
 * @returns Success
 */
export const updateEventLock = async (eventId = 5, is_locked = 0) => {
  let payload = { event_id: eventId, is_locked };

  return await axios.put(`event/${eventId}`, queryString.stringify(payload));
};

/**
 *
 * @param {*} access_code
 * @returns Check event is valid and return event id
 */
export const joinService = async (access_code = "") => {
  return await axios.get(`join/${access_code}`);
};

/**
 *
 * @param {*} payload : {event_id, name, timezone}
 * @returns create interpreter entry as a join event and return Partcipant Id
 */
export const interpreterJoinService = async (payload) => {
  return await axios.post(`interpreter`, queryString.stringify(payload));
};
/**
 *
 * @param {*} payload : {event_id, name, timezone}
 * @returns create client entry as a join event and return Partcipant Id
 */
export const clientJoinService = async (payload) => {
  return await axios.post(`client`, queryString.stringify(payload));
};

/**
 *
 * @param {*} payload : {event_id, name, timezone, audio_in_use}
 * @returns create moderator entry as a join event and return Partcipant Id
 */
export const moderatorJoinService = async (payload) => {
  return await axios.post(`moderator`, queryString.stringify(payload));
};
/**
 *
 * @param {*} payload : {participantId, name, timezone}
 * @returns Exit interpreter from console
 */
export const interpreterExitService = async (payload) => {
  payload = {
    ...payload,
    name: "",
    booth_language_id: "",
    cross_fade: "",
    volume: "",
    is_mic_on: "",
    is_silent: "",
    is_active: "",
    is_audio_enabled: "",
    is_video_enabled: "",
    audio_in_device: "",
    audio_out_device: "",
    camera: "",
    disconnect_request: 0,
  };
  return await axios.put(`interpreter`, queryString.stringify(payload));
};

/**
 *
 * @param {*} payload : {participantId, name, timezone}
 * @returns Exit Client from console
 */
export const clientExitService = async (payload) => {
  payload = {
    ...payload,
    name: "",
    disconnect_request: 0,
  };
  return await axios.put(`client`, queryString.stringify(payload));
};

/**
 *
 * @param {*} payload : {participantId, name, timezone}
 * @returns Exit moderator from console
 */
export const moderatorExitService = async (payload) => {
  payload = {
    ...payload,
    name: "",
    audio_in_device: "",
    language_id: "",
    disconnect_request: 0,
  };
  return await axios.put(`moderator`, queryString.stringify(payload));
};
/**
 *
 * @param {*} payload : {participantId, name}
 * @returns Exit interpreter from console
 */
export const interpreterUpdateService = async (payload) => {
  payload = {
    is_connected: 1,
    name: "",
    booth_language_id: "",
    cross_fade: "",
    volume: "",
    is_mic_on: "",
    is_silent: "",
    is_active: "",
    is_audio_enabled: "",
    is_video_enabled: "",
    audio_in_device: "",
    audio_out_device: "",
    camera: "",
    disconnect_request: 0,
    ...payload,
  };
  return await axios.put(`interpreter`, queryString.stringify(payload));
};

/**
 * @param
 */

export const moderatorUpdateService = async (payload) => {
  payload = {
    name: "",
    audio_in_device: "",
    language_id: "",
    disconnect_request: 0,
    ...payload,
  };

  return await axios.put("moderator", queryString.stringify(payload));
};

/**
 *
 * @param {*} payload : {participantId, name}
 * @returns Exit interpreter from console
 */
export const clientUpdateService = async (payload) => {
  payload = {
    is_connected: 1,
    name: "",
    disconnect_request: 0,
    ...payload,
  };
  return await axios.put(`client`, queryString.stringify(payload));
};

/**
 *
 * @param {*} participant_id, last_read_timestamp
 * @returns chat ui logs
 */
export const getChatUi = async (payload) => {
  return await axios.post(`activity-log`, queryString.stringify(payload));
};
export const getQuickChat = async (participant_id) => {
  return await axios.get(`quickchat/${participant_id}`);
};
export const sendQuickMessageService = async (payload) => {
  return await axios.post(`quickchat`, queryString.stringify(payload));
};

export const sendChatMessageService = async (payload) => {
  return await axios.post(`chat`, queryString.stringify(payload));
};
export const sendReportDialogService = async (payload) => {
  return await axios.post(`issue-modal`, queryString.stringify(payload));
};
export const fileUploadService = async (payload) => {
  return await axios.post(`chat-upload`, queryString.stringify(payload));
};
export const quickChatReleaseService = async (participant_id) => {
  return await axios.get(`quickchat-release-interpreters/${participant_id}`);
};
