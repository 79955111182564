
import { useDispatch, useSelector } from 'react-redux';
import { disconnected, setParticipantIdAction, setEventJoinedAction, setDisplayNameAction, setInitialStateAction } from '../../Redux/Reducers/Connection';
import { interpreterExitService, clientExitService, moderatorExitService } from "../../Services/interceptorServices";
import { toast } from 'react-toastify';

const ExitConsoleDialog = () => {
    const dispatch = useDispatch();
    const { participantId, participant_type } = useSelector(({ connection }) => connection);

    const closeModal = () => {
        document.getElementById("exitConsole-modal").style.display = "none";
        document.getElementById("exitConsole-modal").classList.toggle("show")
        document.getElementById("backDrop").classList.toggle("modal-backdrop");
        document.getElementById("backDrop").classList.toggle("show");
    }

    const exitSettings = (resp) => {
        // console.log("resp=>", resp);;
        if (resp && resp.data && resp.data.code === 200) {
            dispatch(setParticipantIdAction(0));
            dispatch(setEventJoinedAction(false));
            dispatch(setDisplayNameAction(""));

            localStorage.clear();
            dispatch(setInitialStateAction());
            toast.success("Exit from console Successfully!");

            closeModal();
            // dispatch(disconnected());
            setTimeout(() => {
                window.location.href = "https://rsi-now.com";
            }, [100]);
        }
    }

    const exitConsole = () => {

        if (participant_type === "client") {
            clientExitService({
                participant_id: participantId,
                is_connected: 0
            }).then((resp) => {
                exitSettings(resp);
            });
        }
        if (participant_type === "interpreter") {
            interpreterExitService({
                participant_id: participantId,
                is_connected: 0
            }).then(resp => {
                exitSettings(resp);
            });
        }
        if (participant_type === "moderator") {
            moderatorExitService({
                participant_id: participantId,
                is_connected: 0
            }).then(resp => {
                exitSettings(resp);
            });
        }
    }

    return (
        <div className="modal fade " tabIndex="-1" role="dialog" id="exitConsole-modal" aria-modal="true" style={{ display: "none" }}>
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" >Exit Console</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={() => closeModal()} aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" >
                        <div className="text-center">Are you sure you want to exit?</div>
                    </div>
                    <div className="modal-footer " style={{ display: "block" }}>
                        <div className="text-center">
                            <button type="button" className="btn btn-success mr-3" onClick={() => exitConsole()}>Exit</button>
                            <button type="button" className="btn btn-secondary" onClick={() => closeModal()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExitConsoleDialog;