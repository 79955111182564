import { useDispatch, useSelector } from "react-redux";
import {
  setAudioEnabledAction,
  setVideoEnabledAction,
  changeShowSettingsAction,
  changeShowQuickMessagesAction,
} from "../Redux/Reducers/Connection";
import { toast } from "react-toastify";
import { commonMessage } from "../helpers/commonMessage";

export default function MuteControl({ eventDetail }) {
  const {
    localTracks,
    videoEnabled,
    audioEnabled,
    mainMicMute,
    showSettings,
    showQuickMessages,
  } = useSelector((state) => state.connection);

  const dispatch = useDispatch();

  return (
    <>
      <div className="hint" style={{ display: "none" }}>
        Use the toggles to enable/disable audio or video in the console.
      </div>

      <div className="mb-1">
        <a
          onClick={() => {
            if (eventDetail.enable_video != "1") {
              return false;
            }

            let videoTrack = localTracks.find(
              (track) => track.getType() === "video"
            );

            // console.log("checking=>", JitsiMeetJS);
            if (!videoTrack) {
              toast.dismiss()
              return toast.error(commonMessage.videoDeviceNotAvailable);
            }
            dispatch(setVideoEnabledAction(!videoEnabled));
            try {
              if (videoTrack?.isMuted()) {
                videoTrack?.unmute();
              } else {
                videoTrack?.mute();
              }
            } catch (err) {
              return toast.error(commonMessage.videoDeviceNotAvailable);
            }

            // localTracks.map((track) => {
            //     console.log("track=>", track);
            //     if (track.getType() === "video") {
            //         console.log("video in-.", track);
            //         if (track.isMuted()) {
            //             track.unmute();
            //         } else {
            //             track.mute();
            //         }
            //     }
            // });
          }}
        >
          <i
            id="enable-video-switch"
            className={`fa-solid ${
              videoEnabled ? "fa-toggle-on" : "fa-toggle-off"
            } fa-xl text-primary mr-2 ${
              eventDetail && eventDetail.enable_video !== "1"
                ? "text-muted"
                : ""
            } `}
          ></i>
        </a>
        Enable My Video
      </div>
      <div>
        <a
          onClick={() => {
            if (eventDetail.enable_audio != "1") {
              return false;
            }
            localTracks.map((track) => {
              if (track.getType() === "audio") {
                if (!audioEnabled) {
                  // When User Switch on "Enable my audio" button then check main mic should not mute
                  if (!mainMicMute) {
                    track.unmute();
                  }
                } else {
                  // When User Switch off "Enable my audio"
                  track.mute();
                }
                dispatch(setAudioEnabledAction(!audioEnabled));
              }
            });
          }}
        >
          <i
            id="enable-audio-switch"
            className={`fa-solid ${
              audioEnabled ? "fa-toggle-on" : "fa-toggle-off"
            } fa-xl text-primary mr-2 ${
              eventDetail && eventDetail.enable_audio !== "1"
                ? "text-muted"
                : ""
            } `}
          ></i>
        </a>
        Enable My Audio
      </div>
      <div>
        <a
          onClick={() => {
            dispatch(changeShowSettingsAction(!showSettings));
          }}
        >
          <i
            id="enable-audio-switch"
            className={`fa-solid ${
              showSettings ? "fa-toggle-on" : "fa-toggle-off"
            } fa-xl text-primary mr-2  `}
          ></i>
        </a>
        Show Settings
      </div>
      <div>
        <a
          onClick={() => {
            dispatch(changeShowQuickMessagesAction(!showQuickMessages));
          }}
        >
          <i
            id="enable-audio-switch"
            className={`fa-solid ${
              showQuickMessages ? "fa-toggle-on" : "fa-toggle-off"
            } fa-xl text-primary mr-2  `}
          ></i>
        </a>
        Show Quick Message
      </div>
    </>
  );
}
