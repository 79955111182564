/* global JitsiMeetJS */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disconnected } from "./Redux/Reducers/Connection";
import LandingPage from "./pages/LandingPage";
import ConferenceWrapper from "./pages/ConferenceWrapper";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toastify";
import LimiterNode from "audio-limiter";
import NoInternetConnectionWrapper from "./components/NoInternetConnectionWrapper";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import sign from "jwt-encode";

const AudioContext = window.AudioContext || window.webkitAudioContext;

export default function App() {
  const dispatch = useDispatch();
  const {
    themeMode,
    isEventJoined,
    videoInputDeviceId,
    audioInputDeviceId,
    audioOutputDeviceId,
    displayName,
    event_id,
    booth_language_id,
  } = useSelector((state) => state.connection);
  const [connection, setConnection] = useState(null);
  window.APP.audioContext = new AudioContext();
  window.APP.audioContextDestination =
    window.APP.audioContext.createMediaStreamDestination();
  window.APP.limiter = new LimiterNode(window.APP.audioContext, {
    time: 0.005,
  });

  const unloadEventListener = () => {
    dispatch(disconnected());
  };

  const getStarted = () => {
    let room = "meet-room-1";

    const options = {
      hosts: {
        domain: "meet.jitsi",
        muc: "muc.meet.jitsi",
      },
      serviceUrl: `https://${process.env.REACT_APP_JITSI_URL}/http-bind`,
      deploymentInfo: {},
    };
    const initOptions = {
      disableAP: true,
      disableAEC: true,
      disableNS: true,
      disableAGC: true,
      disableHPF: true,
      stereo: true,
      disableLipSync: true,
      maxaveragebitrate: 510000,
      enableTcc: true,
    };
    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
    JitsiMeetJS.init(initOptions);

    let plainObj = {
      context: {
        user: {
          name: displayName,
        },
      },
      aud: process.env.REACT_APP_JITSI_APP_ID,
      iss: process.env.REACT_APP_JITSI_SECRET_ID,
      sub: process.env.REACT_APP_JITSI_URL,
      room: `${process.env.REACT_APP_JITSI_ROOM}-${event_id}-${booth_language_id}`,
      exp: 2550716253,
      nbf: 1697004697,
    };

    let jw = sign(plainObj, process.env.REACT_APP_JWT_SECRET);

    let con = new JitsiMeetJS.JitsiConnection(null, jw, options);
    setConnection(con);
    con.connect();

    window.addEventListener("beforeunload", unloadEventListener);
  };

  useEffect(() => {
    getStarted();

    return () => {
      dispatch(disconnected());
      window.removeEventListener("beforeunload", unloadEventListener);
    };
  }, []);

  return (
    <div className={`App ${themeMode}`}>
      <ToastContainer className="toast-position" position="top-center" />
      <NoInternetConnectionWrapper
        setOnline={(flag) => {
          if (flag) {
            window.location.reload();
          }
        }}
      >
        <Header />
        {!isEventJoined && <LandingPage />}
        {isEventJoined && (
          <ConferenceWrapper
            connection={connection}
            videoInputDeviceId={videoInputDeviceId}
            audioInputDeviceId={audioInputDeviceId}
            audioOutputDeviceId={audioOutputDeviceId}
          />
        )}
        <Footer />
      </NoInternetConnectionWrapper>
    </div>
  );
}
