import ReportDialog from './Dialog/ReportDialog';
import ExitConsoleDialog from './Dialog/ExitConsoleDialog';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { changeThemeModeAction } from "../Redux/Reducers/Connection";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Header = () => {
    const { isEventJoined, themeMode } = useSelector(state => state.connection);
    const [showHint, setShowHint] = useState(false);
    const dispatch = useDispatch();

    const toggleHint = () => {

        let elements = document.getElementsByClassName("hint");
        if (elements && elements.length) {
            for (let element of elements) {
                element.style.display = !showHint ? "block" : "none";
            }
        }
        setShowHint(!showHint);
    }

    const showReportModal = () => {
        document.getElementById("report-modal").style.display = "block";
        document.getElementById("report-modal").classList.add("show");
        document.getElementById("backDrop").classList.add("modal-backdrop");
        document.getElementById("backDrop").classList.add("show");
    }

    const refresh = () => {
        window.location.reload();
    }

    const exitConsole = () => {
        document.getElementById("exitConsole-modal").style.display = "block";
        document.getElementById("exitConsole-modal").classList.add("show");
        document.getElementById("backDrop").classList.add("modal-backdrop");
        document.getElementById("backDrop").classList.add("show");
    }

    const changeTheme = () => {
        dispatch(changeThemeModeAction(themeMode === "light" ? "dark" : "light"));
    }

    let themeSwitchProps = {};
    if (themeMode === "dark") {
        themeSwitchProps.checked = true;
    }

    return (
        <nav className="main-header navbar navbar-expand-md navbar-white">
            <div className="container-fluid">
                <a href="/" className="navbar-brand">
                    <LazyLoadImage src="/logo_world.png" alt="InterStar ICP" className="brand-image elevation-0 opacity-8" effect='blur' />
                    {/* <img src="/logo_world.png" alt="InterStar ICP" className="brand-image elevation-0 opacity-8" /> */}
                    <span className="brand-text font-weight-light">InterStar ICP</span>
                </a>

                <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">

                    <li className="nav-item mr-3">
                        <div className="form-check form-switch mt-2 pe-auto " >
                            <input className="form-check-input cursor mr-1-" onChange={() => { changeTheme() }} type="checkbox" role="switch" {...themeSwitchProps} id="flexSwitchCheckDefault" />
                            <label className="form-check-label mx-2- text-center mt-1- " htmlFor="flexSwitchCheckDefault"> Dark Mode</label>
                        </div>
                    </li>
                    <li className="nav-item mr-3">
                        <button id="hints-btn" className={`btn ${showHint ? "btn-primary" : "btn-light"} btn-sm border-dark`} onClick={() => toggleHint()}>
                            <i className="far fa-lightbulb-on"></i>Hints
                        </button>
                    </li>

                    {isEventJoined && <li className="nav-item mr-3">
                        <button className="btn btn-light btn-sm border-dark" onClick={() => showReportModal()}>
                            Report Issue
                        </button>
                    </li>
                    }
                    <li className="nav-item mr-3">
                        <button className="btn btn-light btn-sm border-dark" onClick={() => refresh()}>
                            Refresh
                        </button>
                    </li>
                    {isEventJoined &&
                        <li className="nav-item mr-3">
                            <button className="btn btn-light btn-sm border-dark" onClick={() => {
                                window.open("https://interstaricp.com/forums/");
                            }}>
                                Support
                            </button>
                        </li>
                    }
                    {isEventJoined &&
                        <li className="nav-item ml-3 mr-3">
                            <button className="btn btn-light btn-sm border-dark" onClick={() => exitConsole()}>
                                Exit Console
                            </button>
                        </li>
                    }
                </ul>
            </div>
            <div id={"backDrop"} className="fade "></div>
            <ExitConsoleDialog />
            <ReportDialog />
        </nav>
    )
}

export default Header;