import React, { useState, useEffect } from 'react';
import "../noInternet.css"
import { toast } from "react-toastify";

const NoInternetConnectionWrapper = (props) => {
    // state variable holds the state of the internet connection

    const [isOnline, setOnline] = useState(true);


    // On initization set the isOnline state.

    let slowLoad = null;

    useEffect(() => {
        setOnline(navigator.onLine);
        slowLoad = setTimeout(() => {
            toast.warning("Slow Internet connection detected")
        }, 4000);
    }, [])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true);
        props.setOnline(true);
    });

    window.addEventListener('offline', () => {
        setOnline(false);
        props.setOnline(false);
    });

    window.addEventListener('load', () => {
        clearTimeout(slowLoad);
    })

    // if user is online, return the child component else return a custom component
    if (isOnline) {
        return (
            props.children
        )
    } else {
        return (
            <div className="container my-5- d-flex justify-content-center login-container">
                <div className="row  justify-content-center ">
                    <div className="col">
                        <div className="card ">
                            <div className="login-card-header pb-0 bg-white border-0 text-center px-sm-4">
                                <h6 className="text-left- mt-4 font-weight-bold mb-0 text-center">
                                    <span style={{ verticalAlign: "middle" }}><i className="fa fa-times-circle fa-2x mr-3 " aria-hidden="true"></i> </span >
                                    No internet connection
                                </h6>
                                <span className="img-1 text-center">
                                    <img src="/media/icons8-wifi.gif" className="img-fluid my-4" alt='WIFI' />
                                </span>
                            </div>
                            <div className="card-body px-sm-4 mb-3">
                                <ul className="list-unstyled text-muted"> <li>Please re-connect to the internet to continue use Footsteps.</li> <li>If you encounter problems:</li>
                                    <ul className="mt-2 inner">
                                        <li>Try restarting wireless connection on this device.</li>
                                        <li>Move clouse to your wireless access point.</li>
                                    </ul>
                                </ul>
                                <div className="row justify-content-end mt-4 "> <div className="col-auto"><button type="button" onClick={() => {
                                    window.location.reload();
                                }} className="btn btn-success"><span >Try Again</span></button></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NoInternetConnectionWrapper;