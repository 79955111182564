import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { sendReportDialogService } from "../../Services/interceptorServices";


const ReportDialog = () => {

    const { participantId, participant_type } = useSelector((state) => state.connection);


    let schema = {};

    if (participant_type === 'moderator') {
        schema = yup.object({
            description: yup.string().required('This filed is mandatory'),

            email: yup.string().email('Enter a valid email address').required('This filed is mandatory')

        })
    } else {
        schema = yup.object({
            description: yup.string().required('This filed is mandatory'),
            email: yup.string()
        })
    }



    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });


    const closeModal = () => {
        if (participant_type === 'moderator') {
            reset({
                description: "",
                email: ""
            });
        } else {
            reset({
                description: ""
            });
        }
        document.getElementById("report-modal").style.display = "none";
        document.getElementById("report-modal").classList.toggle("show")
        document.getElementById("backDrop").classList.toggle("modal-backdrop");
        document.getElementById("backDrop").classList.toggle("show");
    }



    const onSubmit = (message) => {
        console.log('*****message', message);
        sendReportDialogService({
            participant_id: participantId,
            description: message.description,
            email: message.email ? message.email : ''
        }).then(resp => {
            if (resp && resp.data && resp.data.code === 200) {
                toast.success("Reported successfully!");
                closeModal();
            }

        }).catch(err => {
            console.log("***ERR**", err);
        });

    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal fade " tabIndex="-1" role="dialog" id="report-modal" aria-modal="true" style={{ display: "none" }}>
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" >Report an Issue</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => closeModal()} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body" >
                            <p className="mb-2">Your info will be submitted along with this report. This form is not intended for real-time reporting. Please enter a detailed description about the issue you're experiencing:</p>
                            <div className="form-group">
                                <div className="mb-3">
                                    <textarea name="description" cols="40" rows="5" {...register('description')} className="form-control bg-light border border-dark" ></textarea>
                                    <span className="invalid-feedback" style={{ display: "block" }}>{errors.description?.message}</span>
                                </div>
                                {participant_type === "moderator" && <div>
                                    <p className="mb-2">If you'd like us to follow up with you please enter an email address:</p>
                                    <div className="mb-3">
                                        <input type="email" name="email" placeholder="you@email.com"  {...register('email')} className="form-control bg-light border border-dark"></input>
                                        <span className="invalid-feedback" style={{ display: "block" }}>{errors.email?.message}</span>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer " style={{ display: "block" }}>
                            <div className="text-center">
                                <button type="submit" className="btn btn-success mr-3">Submit</button>
                                <button type="button" className="btn btn-secondary" onClick={() => closeModal()}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </form>

    )
}


export default ReportDialog;