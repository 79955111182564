/* global JitsiMeetJS */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDevicesAction,
  localTracksReplacedAction,
  localSingleTrackAddedAction,
  setAudioVideoDeviceIdAction,
  setDisplayNameAction,
  setBoothIdAction,
} from "../../Redux/Reducers/Connection";
import { capitalizeFirstLetter } from "../../helpers/common";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { commonMessage } from "../../helpers/commonMessage";

const PreJoinedControll = (props) => {
  // console.log("******PreJoined");
  const {
    participant_type,
    displayName,
    booth_language_id,
    devices,
    videoInputDeviceId,
    audioInputDeviceId,
    audioOutputDeviceId,
  } = useSelector((state) => state.connection);

  const dispatch = useDispatch();
  const { boothList } = props;

  const [isDeviceFetched, setDeviceFetched] = useState(false);

  useEffect(() => {
    if (isDeviceFetched) {
      const audioInputDevices = devices.filter((d) => d.kind === "audioinput");
      const videoInputDevices = devices.filter((d) => d.kind === "videoinput");

      if (!audioInputDevices.length && !videoInputDevices?.length) {
        toast.error(commonMessage?.noAudioVideoDeviceFound);
      } else if (!audioInputDevices?.length) {
        toast.error(commonMessage?.audioDeviceNotAvailable);
      } else if (!videoInputDevices?.length) {
        toast.error(commonMessage?.videoDeviceNotAvailable);
      }
    }
  }, [devices, isDeviceFetched === true]);

  async function interpreterDevices() {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(() => {
        JitsiMeetJS.createLocalTracks({
          devices: ["audio", "video"],
          cameraDeviceId: videoInputDeviceId || "default",
          micDeviceId: audioInputDeviceId || "default",
        })
          .then(() => {})
          .catch((error) => {
            throw error;
          })
          .finally(() => {
            JitsiMeetJS.mediaDevices.enumerateDevices((devicesData) => {
              dispatch(setDevicesAction(devicesData));
            });
            // }
          });
      })
      .catch(() => {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => {
            JitsiMeetJS.createLocalTracks({
              devices: ["audio"],
              micDeviceId: audioInputDeviceId || "default",
            })
              .then(() => {})
              .catch((error) => {
                throw error;
              })
              .finally(() => {
                JitsiMeetJS.mediaDevices.enumerateDevices((devicesData) => {
                  dispatch(setDevicesAction(devicesData));
                });
              });
          })
          .catch(() => {
            navigator.mediaDevices.getUserMedia({ video: true }).then(() => {
              JitsiMeetJS.createLocalTracks({
                devices: ["video"],
                cameraDeviceId: videoInputDeviceId || "default",
              })
                .then(() => {})
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  JitsiMeetJS.mediaDevices.enumerateDevices((devicesData) => {
                    dispatch(setDevicesAction(devicesData));
                  });
                  // }
                });
            });
          });
      })
      .finally(() => {
        setTimeout(() => {
          setDeviceFetched(true);
        }, 1000);
      });
  }

  useEffect(() => {
    if (participant_type === "interpreter") {
      interpreterDevices();
    }
    if (participant_type === "moderator") {
      JitsiMeetJS.createLocalTracks({
        devices: ["audio"],
        micDeviceId: audioInputDeviceId || "default",
      })
        .then(() => {})
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          JitsiMeetJS.mediaDevices.enumerateDevices((devicesData) => {
            dispatch(setDevicesAction(devicesData));
          });
        });
    }
  }, []);

  const renderDeviesList = (type = "audioInput") => {
    const audioOutputDevices = devices.filter(
      (d) => d.kind === "audiooutput" && d.label && d.deviceId
    );
    const audioInputDevices = devices.filter((d) => d.kind === "audioinput");
    const videoInputDevices = devices.filter((d) => d.kind === "videoinput");

    if (type === "audioInput") {
      return audioInputDevices.map((d, index) => {
        let extraProps = {};
        if (audioInputDeviceId === d.deviceId) {
          extraProps.defaultValue = "selected";
        }
        return (
          <option key={index} {...extraProps} value={d.deviceId}>
            {d.label}
          </option>
        );
      });
    }
    if (type === "audioOutput") {
      if (audioOutputDevices.length === 0) {
        let odevice = JitsiMeetJS.mediaDevices.getAudioOutputDevice();
        console.log("odevice==>", odevice);
        return (
          <option value={odevice}>
            {capitalizeFirstLetter(odevice)} Speaker
          </option>
        );
      }
      return audioOutputDevices.map((d, index) => {
        let extraProps = {};
        if (audioOutputDeviceId === d.deviceId) {
          extraProps.defaultValue = "selected";
        }
        return (
          <option key={index} {...extraProps} value={d.deviceId}>
            {d.label || "default"}
          </option>
        );
      });
    }
    if (type === "videoInput") {
      return videoInputDevices.map((d, index) => {
        let extraProps = {};
        if (videoInputDeviceId === d.deviceId) {
          extraProps.defaultValue = "selected";
        }
        return (
          <option key={index} {...extraProps} value={d.deviceId}>
            {d.label}
          </option>
        );
      });
    }
  };

  const changeAudioInput = (deviceId) => {
    // dispatch(localSingleTrackRemovedAction("audio"));
    dispatch(
      setAudioVideoDeviceIdAction({
        type: "audioinput",
        deviceId: deviceId,
      })
    );

    JitsiMeetJS.createLocalTracks({ devices: ["audio"], micDeviceId: deviceId })
      .then((tracks) => {
        if (tracks && tracks.length) {
          dispatch(
            localSingleTrackAddedAction({ track: tracks[0], type: "audio" })
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const changeVideoInput = (deviceId) => {
    // dispatch(localTrackRemovedAction("video"));
    dispatch(
      setAudioVideoDeviceIdAction({
        type: "videoinput",
        deviceId: deviceId,
      })
    );

    JitsiMeetJS.createLocalTracks({
      devices: ["video", "audio"],
      cameraDeviceId: deviceId,
    })
      .then((tracks) => {
        if (tracks && tracks.length) {
          dispatch(localTracksReplacedAction(tracks));
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  let videoProps = {};

  const getBooths = () => {
    let sortedLanguages = Object.values(boothList)
      .map((value) => value)
      .sort();
    return sortedLanguages.map((booth, index) => {
      let id = Object.keys(boothList).find((key) => {
        return boothList[key] === booth;
      });
      return (
        <option key={index} value={id}>
          {boothList[id]}
        </option>
      );
    });
  };

  const onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (
      event.key === "Enter" &&
      (participant_type === "moderator" || participant_type === "client")
    ) {
      event.preventDefault();
      event.stopPropagation();
      props.submit();
    }
  };

  function checkCookies(deviceName) {
    if (deviceName === "audioInput") {
      if (audioInputDeviceId === "default" && Cookies.get("audioInput")) {
        dispatch(
          setAudioVideoDeviceIdAction({
            type: "audioinput",
            deviceId: Cookies.get("audioInput"),
          })
        );
        return Cookies.get("audioInput");
      } else {
        return audioInputDeviceId;
      }
    } else if (deviceName === "audioOutput") {
      if (audioOutputDeviceId === "default" && Cookies.get("audioOutput")) {
        dispatch(
          setAudioVideoDeviceIdAction({
            type: "audiooutput",
            deviceId: Cookies.get("audioOutput"),
          })
        );
        return Cookies.get("audioOutput");
      } else {
        return audioOutputDeviceId;
      }
    } else if (deviceName === "videoInput") {
      if (videoInputDeviceId === "default" && Cookies.get("videoInput")) {
        dispatch(
          setAudioVideoDeviceIdAction({
            type: "videoinput",
            deviceId: Cookies.get("videoInput"),
          })
        );
        return Cookies.get("videoInput");
      } else {
        return videoInputDeviceId;
      }
    }
  }

  return (
    <div className="row">
      <div className="col-12 col-lg-12">
        <div className="form-group">
          <label>Your Name</label>
          <input
            className="form-control border p-2 bg-light border-dark"
            type={"text"}
            name="name"
            value={displayName}
            onChange={(e) => {
              dispatch(setDisplayNameAction(e.target.value));
            }}
            onKeyDown={onKeyDown}
            autoComplete="off"
          />
        </div>
      </div>
      <div
        className="col-12 col-lg-12"
        style={{
          display: participant_type === "interpreter" ? "block" : "none",
        }}
      >
        <div className="form-group">
          <label>Booth</label>
          <select
            defaultValue={booth_language_id}
            name="booth_language_id"
            id="booth-language-id"
            className="custom-select bg-light border border-dark"
            value={booth_language_id}
            onChange={(e) => {
              dispatch(setBoothIdAction(e.target.value));
            }}
          >
            {getBooths()}
          </select>
        </div>
      </div>
      <div
        className="col-12 col-lg-12"
        style={{ display: participant_type !== "client" ? "block" : "none" }}
      >
        <div className="form-group">
          <label>Listen Through</label>
          <select
            name="device"
            id="audioOutputSelect"
            onChange={(e) => {
              JitsiMeetJS.mediaDevices.setAudioOutputDevice(e.target.value);
              dispatch(
                setAudioVideoDeviceIdAction({
                  type: "audiooutput",
                  deviceId: e.target.value,
                })
              );
            }}
            className="custom-select bg-light border border-dark"
            value={checkCookies("audioOutput")}
            // defaultValue={audioOutputDeviceId}
          >
            {/* <option value="">Choose...</option> */}
            {renderDeviesList("audioOutput")}
          </select>
          <div className="small text-muted">Leave as Default if unsure</div>
        </div>
      </div>
      <div
        className="col-12 col-lg-12"
        style={{
          display: participant_type === "interpreter" ? "block" : "none",
        }}
      >
        <div className="form-group">
          <label>Microphone</label>
          <select
            name="micdevice"
            id={"audioInputSelect"}
            onChange={(e) => changeAudioInput(e.target.value)}
            className="custom-select bg-light border border-dark"
            // defaultValue={audioInputDeviceId}
            value={checkCookies("audioInput")}
          >
            {/* <option value="">Choose...</option> */}
            {renderDeviesList("audioInput")}
          </select>
          <div className="small text-muted">Leave as Default if unsure</div>
        </div>
      </div>
      <div
        className="col-12 col-lg-12"
        style={{
          display: participant_type === "interpreter" ? "block" : "none",
        }}
      >
        <div className="form-group">
          <label>Camera</label>
          <select
            {...videoProps}
            // defaultValue={videoInputDeviceId}4
            value={checkCookies("videoInput")}
            id={"videoInputSelect"}
            onChange={(e) => changeVideoInput(e.target.value)}
            name="cameradevice"
            className="custom-select bg-light border border-dark"
          >
            {/* <option value="">Choose...</option> */}
            {renderDeviesList("videoInput")}
          </select>
          <div className="small text-muted">Leave as Default if unsure</div>
        </div>
      </div>
    </div>
  );
};

export default PreJoinedControll;
