import { CircularProgress } from "@mui/material";
import {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getChatUi,
  sendChatMessageService,
  fileUploadService,
} from "../Services/interceptorServices";
import { Dropzone } from "dropzone";
import { API_BASE_URL } from "../helpers/constant";
import { setChatView } from "../Redux/Reducers/Connection";
import { useQuery } from "@tanstack/react-query";
import axios from "../helpers/axios";
import queryString from "query-string";

Dropzone.autoDiscover = false;

let intervalId;
const Chat = ({ eventDetail, callAlert, chimeEnabled }) => {
  const chatRef = useRef(null);
  const dispatch = useDispatch();
  const {
    participantId,
    booth_language_id,
    participant_type,
    enableTextChat,
    chatView,
  } = useSelector((state) => state.connection);
  const [showAllMessage, setShowAllMessage] = useState(false);
  const [logs, setLogs] = useState({
    unread_alert_count: 0,
    entries: [],
  });
  const [chatLength, setChatLength] = useState(0);
  const [isNewMessage, setNewMessageAlert] = useState(false);
  const [boothName, setBoothName] = useState("English");
  const [selectedBooth, setSelectedBooth] = useState(0);
  const [message, setMessage] = useState("");
  const [loadMessage, setLoadMessage] = useState(true);

  const scrollPosition = useRef(0);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const { data } = useQuery({
    queryKey: ["chat", participantId],
    queryFn: async () => {
      let last_read_timestamp_value = localStorage.getItem(
        "last_read_timestamp_value"
      );
      const chatResponse = await getChatUi({
        participant_id: participantId,
        last_read_timestamp: last_read_timestamp_value || "",
      });

      if (loadMessage) {
        setLoadMessage(false);
      }

      return chatResponse.data;
    },
    refetchInterval: 2000,
  });

  useEffect(() => {
    if (data && data.code === 200) {
      setLogs(data.data);

      setChatLength((prevS) => {
        if (data.data?.entries?.length > prevS && scrollPosition.current != 0) {
          setNewMessageAlert(true);
        }
        return data.data?.entries?.length || 0;
      });

      localStorage.setItem(
        "last_read_timestamp",
        data.data.last_read_timestamp
      );

      if (data.data && data.data.entries && data.data.entries.length > 0) {
        let chatLogs = data.data.entries;
        for (let chatLog of chatLogs) {
          if (chatLog.is_alert === 1 && chatLog.is_read === 0) {
            let timestamp = localStorage.getItem("last_alert_timestamp");

            if (!timestamp) {
              // callAlert();
              localStorage.setItem("last_alert_timestamp", chatLog.timestamp);
              // return false;
            }
            let timestampInMs = Date.parse(timestamp) / 1000;
            let chatLogTimestampInMs = Date.parse(chatLog.timestamp) / 1000;
            if (timestamp && timestampInMs < chatLogTimestampInMs) {
              callAlert();
              localStorage.setItem("last_alert_timestamp", chatLog.timestamp);
              // return false;
            }
          }
        }
        let existTimestamp = localStorage.getItem("last_alert_timestamp");
        if (!existTimestamp) {
          localStorage.setItem(
            "last_alert_timestamp",
            data.data.last_read_timestamp || "2022-11-25 08:54:23"
          );
        }
      }
    }
  }, [data, chimeEnabled]);

  // const getUi = useCallback(() => {
  //   // callAlert();
  //   let last_read_timestamp_value = localStorage.getItem(
  //     "last_read_timestamp_value"
  //   );

  //   getChatUi({
  //     participant_id: participantId,
  //     last_read_timestamp: last_read_timestamp_value || "",
  //   })
  //     .then((resp) => {
  //       if (resp && resp.data && resp.data.code === 200) {
  //         setLogs(resp.data.data);

  //         setChatLength((prevS) => {
  //           if (
  //             resp.data.data?.entries?.length > prevS &&
  //             scrollPosition.current != 0
  //           ) {
  //             setNewMessageAlert(true);
  //           }
  //           return resp.data.data?.entries?.length || 0;
  //         });

  //         localStorage.setItem(
  //           "last_read_timestamp",
  //           resp.data.data.last_read_timestamp
  //         );

  //         if (
  //           resp.data.data &&
  //           resp.data.data.entries &&
  //           resp.data.data.entries.length > 0
  //         ) {
  //           let chatLogs = resp.data.data.entries;
  //           for (let chatLog of chatLogs) {
  //             if (chatLog.is_alert === 1 && chatLog.is_read === 0) {
  //               let timestamp = localStorage.getItem("last_alert_timestamp");

  //               if (!timestamp) {
  //                 // callAlert();
  //                 localStorage.setItem(
  //                   "last_alert_timestamp",
  //                   chatLog.timestamp
  //                 );
  //                 return false;
  //               }
  //               let timestampInMs = Date.parse(timestamp) / 1000;
  //               let chatLogTimestampInMs = Date.parse(chatLog.timestamp) / 1000;
  //               if (timestamp && timestampInMs < chatLogTimestampInMs) {
  //                 callAlert();
  //                 localStorage.setItem(
  //                   "last_alert_timestamp",
  //                   chatLog.timestamp
  //                 );
  //                 return false;
  //               }
  //             }
  //           }
  //           let existTimestamp = localStorage.getItem("last_alert_timestamp");
  //           if (!existTimestamp) {
  //             localStorage.setItem(
  //               "last_alert_timestamp",
  //               resp.data.data.last_read_timestamp || "2022-11-25 08:54:23"
  //             );
  //           }
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("***ERR**", err);
  //     })
  //     .finally(() => {
  //       setLoadMessage(false);
  //     });
  // }, [callAlert, chimeEnabled]);

  // useEffect(() => {
  //   setLoadMessage(true);
  //   const getChatFromServer = () => {
  //     intervalId && clearInterval(intervalId);

  //     intervalId = setInterval(() => getUi(), 2000);
  //   };

  //   getChatFromServer();
  // }, [chimeEnabled]);

  const getFileFromPasteEvent = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (let index in items) {
      const item = items[index];
      if (item.kind === "file") {
        return item.getAsFile();
      }
    }
  };

  const acceptCallback = useCallback(
    (file) => {
      let reader = new FileReader();
      reader.onload = function (event) {
        let imageData = event.target.result;
        document.getElementById("chat-spinner").style.display = "block";
        fileUploadService({
          participant_id: participantId,
          recipient: document
            .getElementById("chat-recipient")
            .getAttribute("slectedValue"),
          data: imageData,
        })
          .then((resp) => {
            if (resp && resp.data && resp.data.code === 200) {
              setMessage("");
              document.getElementById("activity-log").scrollTo(0, 0);
            }
          })
          .catch((err) => {
            console.log("***err***", err);
          })
          .finally(() => {
            document.getElementById("chat-spinner").style.display = "none";
          });
      };
      reader.readAsDataURL(file);
    },
    [selectedBooth]
  );

  useEffect(() => {
    new Dropzone("textarea#chat-input", {
      method: "POST",
      url: API_BASE_URL + "/api/chat-upload",
      headers: {
        token: "abc123",
        Accept: "application/json, text/plain, */*",
        "Cache-Control": null,
        "X-Requested-With": null,
      },
      paramName: "photo",
      uploadMultiple: false,
      maxFilesize: 10,
      acceptedFiles: "image/*",
      addRemoveLinks: false,
      dictDefaultMessage: "",
      clickable: false,
      accept: acceptCallback,
    });
  }, []);

  useEffect(() => {
    if (eventDetail && eventDetail.languages) {
      setBoothName(eventDetail.languages[1] || "English");
      if (participant_type === "moderator") {
        setSelectedBooth(booth_language_id || "-7");
      }
      if (participant_type === "client" || participant_type === "interpreter") {
        setSelectedBooth(booth_language_id || "-2");
      }
    }
  }, [booth_language_id, eventDetail]);

  const getBooths = () => {
    if (eventDetail && eventDetail.languages) {
      let sortedLanguages = Object.values(eventDetail.languages)
        .map((value) => value)
        .sort();

      return sortedLanguages.map((booth, index) => {
        let id = Object.keys(eventDetail.languages).find((key) => {
          return eventDetail.languages[key] === booth;
        });
        if (id == 1) {
          return <Fragment key={index}></Fragment>;
        }
        let optionProps = {};
        if (selectedBooth === id) {
          optionProps.defaultValue = "selected";
        }
        return (
          <option key={index} value={id} {...optionProps}>
            {booth}
          </option>
        );
      });
    }
    return <Fragment></Fragment>;
  };

  const onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event

    if (event.key === "Enter" && !!message.trim() && message.trim() !== "") {
      event.preventDefault();
      event.stopPropagation();

      // Call api call here to send message
      document.getElementById("chat-spinner").style.display = "block";
      sendChatMessageService({
        participant_id: participantId,
        recipient: selectedBooth,
        content: message.trim(),
      })
        .then((resp) => {
          if (resp && resp.data && resp.data.code === 200) {
            setMessage("");
            document.getElementById("activity-log").scrollTo(0, 0);
          }
        })
        .catch((err) => {
          console.log("***err***", err);
        })
        .finally(() => {
          document.getElementById("chat-spinner").style.display = "none";
        });
    }
  };

  const handlePaste = (event) => {
    const file = getFileFromPasteEvent(event);
    if (!file) {
      return;
    }

    console.log("***FILE***", file);
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string

        document.getElementById("chat-spinner").style.display = "block";
        fileUploadService({
          participant_id: participantId,
          recipient: selectedBooth,
          data: reader.result,
        })
          .then((resp) => {
            if (resp && resp.data && resp.data.code === 200) {
              setMessage("");
              document.getElementById("activity-log").scrollTo(0, 0);
            }
          })
          .catch((err) => {
            console.log("***err***", err);
          })
          .finally(() => {
            document.getElementById("chat-spinner").style.display = "none";
          });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }

    // Call api call here to send message
  };

  let chatProps = {};
  if (enableTextChat === false) {
    chatProps.disabled = "disabled";
  }

  function handleScroll(e) {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const position = Math.ceil(
      (scrollTop / (scrollHeight - clientHeight)) * 100
    );
    scrollPosition.current = position;

    if (scrollPosition.current === 0 && isNewMessage) {
      setNewMessageAlert(false);
    }
  }

  function reviewAction() {
    console.log("review");
    if (chatView === "top-bottom") {
      chatRef.current.scroll({
        top: 0,
        behavior: "smooth",
      });
    } else {
      chatRef.current.scroll({
        top: 100,
        behavior: "smooth",
      });
    }
  }

  return (
    <div className="sticky-top">
      <div className="card">
        <div className="p-1 bg-light border-bottom" style={{ borderRadius: 3 }}>
          <div id="pending-alerts-1">
            <div className="d-flex justify-content-between">
              <div className="p-1 fs-chat fw-chat fs-chat-t">ICP Chat</div>
              {isNewMessage ? (
                <div className="newMessageAlert">
                  <p>New Message </p>
                  <span>
                    <button onClick={() => reviewAction()}>Review</button>
                  </span>
                </div>
              ) : null}
              <button
                className="btn chat-btn"
                onClick={() => {
                  if (chatView === "top-bottom") {
                    dispatch(setChatView("bottom-top"));
                  } else if (chatView === "bottom-top") {
                    dispatch(setChatView("top-bottom"));
                  }
                }}
              >
                Chat Direction
                {chatView === "top-bottom" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                  </svg>
                )}
                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/></svg>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg> */}
              </button>
            </div>
          </div>
        </div>

        <div className="card-body p-1">
          <div
            id="activity-log"
            className={`${chatView === "bottom-top" ? "bottomToUpChat" : ""}`}
            style={{ height: "65vh", overflow: "auto" }}
            ref={chatRef}
            onScroll={handleScroll}
          >
            <div className="list-group list-group-flush" id="topMessage">
              {loadMessage && (
                <div className="p-2 text-center">
                  <CircularProgress />
                </div>
              )}

              {logs.entries.length > 0 &&
                logs.entries.map((log, index) => (
                  <>
                    <div
                      className="p-2 border-bottom text-center"
                      id="chat-spinner"
                      style={{ display: "none" }}
                      key={`chat-spinner${index}`}
                    >
                      <div id="spinner">
                        <CircularProgress />
                      </div>
                    </div>
                    <div
                      key={index + index}
                      className={`list-group-item p-2 m-1 border ${
                        log.is_alert == 0 ? "not-alert" : ""
                      } ${
                        showAllMessage && log.is_alert == 0 ? " d-none" : " "
                      } ${
                        log.posted_by.toLowerCase() === "me" ||
                        log.posted_by === "Technician [Me]"
                          ? ""
                          : ""
                      }`}
                    >
                      <div className="">
                        <div className="p-1 fs-chat fw-chat">
                          <div className="text-muted small float-right fs-chat">
                            {log.relative_timestamp}
                          </div>
                          <div>
                            <span className={"fw-chat"}>{log.posted_by}</span>
                            <i className="text-muted mx-1 fa-solid fa-arrow-right fa-xs "></i>
                            {log.posted_to}
                          </div>
                        </div>
                        <div>
                          <div
                            className={`chat-bubble fs-chat fw-chat ${
                              log.posted_by.toLowerCase() === "me" ||
                              log.posted_by === "Technician [Me]"
                                ? " border bg-light bg-light-c"
                                : log.is_alert > 0
                                ? "bg-warning"
                                : "bg-success"
                            } ${log.is_read > 0 ? " opacity-5" : ""}`}
                          >
                            {!log.image_url ? (
                              log.message
                            ) : (
                              <div>
                                <div
                                  className={`chat-bubble ${
                                    log.posted_by.toLowerCase() === "me" ||
                                    log.posted_by === "Technician [Me]"
                                      ? ""
                                      : "bg-success"
                                  }  `}
                                  onClick={() => {
                                    document
                                      .getElementById("chatImageFile")
                                      .setAttribute("src", log.image_url);
                                  }}
                                >
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#imageModal"
                                  >
                                    <img
                                      src={log.thumbnail_url}
                                      alt="Preview"
                                      className={"img-fluid"}
                                    />
                                    <div className="mt-1 font-italic img-label">
                                      Click to enlarge
                                    </div>
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                          {!!log.note && (
                            <div
                              className="fs-chat fw-chat"
                              style={{ marginLeft: 8, color: "red" }}
                            >
                              {log.note}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>

        <div id="chat-form">
          <div id="chat-dd">
            <h4 className="chat-send-to">
              Send to{" "}
              <span>
                <i className="mx-1 fa-solid fa-arrow-right fa-xs "></i>
              </span>
            </h4>
            <select
              id="chat-recipient"
              selectedvalue={selectedBooth}
              value={selectedBooth}
              className="custom-select send-to bg-light bg-lighter border border-dark"
              onChange={(e) => {
                setSelectedBooth(e.target.value);
              }}
            >
              <optgroup label="Chat to...">
                {/* {participant_type === "moderator" && (
                  <option value="-7">All</option>
                )} */}

                {participant_type !== "client" ? (
                  <option value="-7">
                    {participant_type === "moderator"
                      ? "All"
                      : participant_type === "interpreter"
                      ? "Everyone"
                      : null}
                  </option>
                ) : null}

                <option value="-2">Interpreters</option>
                <option value="-1">Technicians</option>
                <option value="-4">Clients</option>
                {/* <option value="-7">Everyone</option> */}
              </optgroup>
              <optgroup label="Booths...">{getBooths()}</optgroup>
              <optgroup label="If occupied...">
                <option
                  value={1}
                  defaultValue={selectedBooth == 1 ? "selected" : ""}
                >
                  {boothName} Booth
                </option>
              </optgroup>
            </select>
          </div>
          <textarea
            id="chat-input"
            {...chatProps}
            onPaste={handlePaste}
            onKeyDown={onKeyDown}
            value={message}
            className={`form-control form-control-sm mt-2 w-100 border border-dark ${
              enableTextChat === true ? "bg-light bg-lighter" : "bg-disabled"
            }`}
            placeholder={
              enableTextChat === true
                ? "Text message or paste your screenshot here"
                : "Chat is disabled for this event"
            }
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default memo(Chat);
