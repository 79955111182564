import { useCallback, Fragment, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import LocalTracks from "./LocalTracks";
import RemoteTrack from "./RemoteTrack";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";
import { Pagination } from "swiper/modules";

const RemoteParticipantsJoinedListing = (props) => {
  // console.log("props=>", props.JitsiMeetJS);

  const videoRef = useRef(null);

  const localTracks = useSelector((state) => state.connection.localTracks);
  const remoteTracksArray = useSelector(
    (state) => state.connection.remoteTracksArray
  );
  const displayName = useSelector((state) => state.connection.displayName);
  const joinedUsers = useSelector((state) => state.connection.joinedUsers);
  const selecteduser = useSelector((state) => state.connection.selecteduser);

  const [showLVideoMsg, setShowLVideoMsg] = useState(true);

  // console.log("*****joinedUsers", joinedUsers);

  // useEffect(() => {
  //   if (videoRef) {
  //     videoRef.current?.addEventListener("wheel", (evt) => {
  //       evt.preventDefault();
  //       videoRef.current.scrollLeft += evt.deltaY;
  //     });
  //   }
  //   return () => {
  //     videoRef.current.removeEventListener("wheel");
  //   };
  // }, [videoRef]);

  useEffect(() => {
    if (localTracks.length && selecteduser) {
      setShowLVideoMsg(false);
    }
  }, [localTracks, selecteduser]);

  const renderAudioVideo = useCallback(
    (id, remoteUserData = null) => {
      let existingTrack = remoteTracksArray.find((item) => item.id === id);

      if (
        !existingTrack ||
        (existingTrack && existingTrack.tracks.length === 0)
      ) {
        return (
          <LazyLoadImage
            className="video-thumb noImage-thumb img-fluid"
            id={`${id}-video-thumb-0001`}
            alt="video"
            src="/media/noPicture.svg"
            effect="blur"
          />
        );
      }
      let isVideoExist = existingTrack.tracks.filter(
        (track) => track.getType() === "video"
      );
      let audioTrackExist = existingTrack.tracks.find(
        (track) => track.getType() === "audio"
      );
      // console.log(" **** existingTrack.tracks ", existingTrack.tracks.map(item => item.getId()))
      return existingTrack.tracks.map((track, index) => {
        // console.log(" **** track  ", track.getId(), remoteTracksArray.length);
        return (
          <RemoteTrack
            key={track.getId()}
            index={index}
            id={id}
            isAudioMuted={audioTrackExist?.isMuted()}
            track={track}
            isVideoExist={isVideoExist}
            remoteUserData={remoteUserData}
            JitsiMeetJS={props.JitsiMeetJS}
            room={props.room}
          />
        );
      });
    },
    [remoteTracksArray, props.JitsiMeetJS, props.room]
  );

  const renderRemoteTrack = useCallback(() => {
    return Object.keys(joinedUsers).map((id, mainIndex) => {
      if (
        joinedUsers[id] &&
        joinedUsers[id]._properties &&
        joinedUsers[id]._properties.participant_type &&
        joinedUsers[id]._properties.participant_type === "interpreter"
      ) {
        return (
          <SwiperSlide className="video-col" key={mainIndex}>
            {/* <div className="video-col" key={mainIndex}> */}
            <div
              className="video-col-bx text-center"
              style={
                selecteduser === id
                  ? { border: "1px solid #007bff", borderRadius: "18px" }
                  : {}
              }
            >
              <div className={"video-render"}>
                {renderAudioVideo(id, joinedUsers[id])}
              </div>
              <div className="content-video-sec">
                <div className="userName">{joinedUsers[id]._displayName}</div>
              </div>
            </div>
            {/* </div> */}
          </SwiperSlide>
        );
      }
      return <Fragment></Fragment>;
    });
  }, [joinedUsers, renderAudioVideo, selecteduser]);

  return (
    <div className="card">
      <div className="card-body videos-card-body">
        <div className="hint" style={{ display: "none" }}>
          Video of your boothmates appear here. Click on a thumbnail to have the
          video appear in the Video tab to the left.
        </div>
        {localTracks.length && showLVideoMsg ? (
          <div className="largeVideoMessage">
            View a larger version of an interpreter's video by clicking their
            thumbnail.
          </div>
        ) : null}
        {localTracks.length == 0 ? (
          <div className="p-1 text-center loading-txt">
            Please wait, while connecting
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
        ) : null}
        {/* <div className="video-row" ref={videoRef}>
          <div className="video-col">
            {localTracks.length > 0 ? (
              <div
                className="video-col-bx text-center"
                style={
                  selecteduser === "local"
                    ? {
                        border: "1px solid #007bff",
                        borderRadius: "18px",
                        // overflow: "hidden",
                      }
                    : {}
                }
              >
                <div className={"video-render"}>
                  <LocalTracks
                    JitsiMeetJS={props.JitsiMeetJS}
                    room={props.room}
                  />
                </div>
                <div className="content-video-sec">
                  <div className="userName">
                    {displayName}
                    <p className='meDisplayName'></p> (Me)
                  </div>
                  <span>(Me)</span>
                </div>
              </div>
            ) : null}
          </div>
          {localTracks.length > 0 ? renderRemoteTrack() : null}
        </div> */}
        <Swiper
          slidesPerView={3}
          spaceBetween={20}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="video-row"
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },

            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },

            500: {
              slidesPerView: 2,
              spaceBetween: 10,
            },

            767: {
              slidesPerView: 1,
              spaceBetween: 10,
            },

            970: {
              slidesPerView: 2,
              spaceBetween: 10,
            },

            1332: {
              slidesPerView: 3,
              spaceBetween: 10,
            },

            1810: {
              slidesPerView: 3,
              spaceBetween: 20,
            },

            2560: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
          ref={videoRef}
        >
          <SwiperSlide className="video-col">
            {" "}
            {localTracks.length > 0 ? (
              <div
                className="video-col-bx text-center"
                style={
                  selecteduser === "local"
                    ? {
                        border: "1px solid #007bff",
                        borderRadius: "18px",
                      }
                    : {}
                }
              >
                <div className={"video-render"}>
                  <LocalTracks
                    JitsiMeetJS={props.JitsiMeetJS}
                    room={props.room}
                  />
                </div>
                <div className="content-video-sec">
                  <div className="userName">{displayName}</div>
                  <span>(Me)</span>
                </div>
              </div>
            ) : null}
          </SwiperSlide>
          {localTracks.length > 0 ? renderRemoteTrack() : null}
        </Swiper>

        {/* <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>Slide 1</SwiperSlide>
          <SwiperSlide>Slide 2</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 4</SwiperSlide>
          <SwiperSlide>Slide 5</SwiperSlide>
          <SwiperSlide>Slide 6</SwiperSlide>
          <SwiperSlide>Slide 7</SwiperSlide>
          <SwiperSlide>Slide 8</SwiperSlide>
          <SwiperSlide>Slide 9</SwiperSlide>
        </Swiper> */}
      </div>
    </div>
  );
};

export default RemoteParticipantsJoinedListing;
