/* global JitsiMeetJS, Slider */

import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import useLongPress from "../hooks/useLongPress";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import {
  setMainMicMuteAction,
  setBoothmatesMuteAction,
  setBoothmateVolumeAction,
  setBoothmateAudioAction,
  setMonitoringMode,
  setVirtualCableId,
  setZoomWebxAudioMute,
  setZoomWebxVolume,
} from "../Redux/Reducers/Connection";
import Meter from "./Meter";
import { commonMessage } from "../helpers/commonMessage";
import { toast } from "react-toastify";

const getColor = (value) => {
  if (value < 40) {
    return "lightgreen";
  }
  if (value > 40 && value < 80) {
    return "yellow";
  }
  return "red";
};

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 15,
  maxWidth: 100,
  marginBottom: 10,
  border: "1px solid lightgrey",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: getColor(value),
  },
}));

const MainAudioControl = () => {
  const {
    localTracks,
    remoteTracks,
    mainMicMute,
    boothmatesMute,
    boothmateVolume,
    selectedVirtualCableId,
    monitoringMode,
    participant_type,
    devices,
    zoomWebxAudioMute,
    zoomWebxVolume,
  } = useSelector((state) => state.connection);

  const [holdMute, setHoldMute] = useState(false);
  const [localAudioLevel, setLocalAudioLevel] = useState(0);
  const [remoteAudioLevel, setRemoteAudioLevel] = useState(0);
  const [boothmateVolumeState, setBoothmateVolumeState] =
    useState(boothmateVolume);

  const audioCtxRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const streamRef = useRef(null);

  const dispatch = useDispatch();

  let room = window.APP?.room;

  const renderDevicesList = (type) => {
    // console.log("devices=>", devices);
    const audioInputDevices = devices.filter((d) => d.kind === type);

    // console.log("audioInputDevices=>", audioInputDevices);

    return audioInputDevices.map((d, index) => {
      return (
        <option key={index} value={d.deviceId}>
          {d.label}
        </option>
      );
    });
  };

  useEffect(() => {
    let mySlider = new Slider("#slide-boothmate", {
      // initial options object
    });
    mySlider.setValue(boothmateVolume);

    mySlider.on("slideStop", () => {
      var value = mySlider.getValue();
      changeBoothmatesVolumeAfterChange(value);
    });

    // mySlider2 = new Slider("#slide-boothmate2", {
    //     // initial options object
    // });
    // mySlider2.on("change", () => {
    //     var value2 = mySlider2.getValue();
    //     console.log('****value***', value2);
    //     changeBoothmatesAudio(value2);

    // });
  }, []);

  // useEffect(() => {
  //   let mySlider = new Slider("#slide-boothmate1", {
  //     // initial options object
  //   });
  //   mySlider.setValue(zoomWebxVolume);

  //   mySlider.on("slideStop", () => {
  //     var value = mySlider.getValue();
  //     dispatch(setZoomWebxVolume(value));
  //   });
  // }, []);
  useEffect(() => {
    let localUserId = 0;
    let localAudioTrack = localTracks.find(
      (track) => track.getType() === "audio"
    );
    if (localAudioTrack) {
      localUserId = localAudioTrack.getParticipantId();
      let trackEvent = (audioLevel) => {
        if (localAudioTrack.getType() === "audio") {
          setLocalAudioLevel(audioLevel > 0.008 ? audioLevel * 200 : 0);
        }
      };
      localAudioTrack.addEventListener(
        JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        trackEvent
      );

      return () => {
        localAudioTrack.removeEventListener(
          JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
          trackEvent
        );
      };
    }
  }, [room, localTracks, localTracks.length]);

  useEffect(() => {
    let localUserId = 0;
    let localAudioTrack = localTracks.find(
      (track) => track.getType() === "audio"
    );
    if (localAudioTrack) {
      localUserId = localAudioTrack.getParticipantId();
    }

    if (room) {
      let audioListener = (userID, audioLevel) => {
        if (localUserId === userID) {
          return;
        }
        if (Object.keys(remoteTracks).length) {
          setRemoteAudioLevel(audioLevel);
        } else {
          setRemoteAudioLevel(0);
        }
      };

      room.on(
        JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
        audioListener
      );

      return () => {
        room.off(
          JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
          audioListener
        );
      };
    }
  }, [room, Object.keys(remoteTracks).length]);

  // useEffect(() => {
  //   if (selectedVirtualCableId) {
  //     setupAudio();
  //   }
  //   return () => {
  //     cleanupAudio();
  //   };
  // }, [
  //   selectedVirtualCableId,
  //   monitoringMode,
  //   zoomWebxAudioMute,
  //   zoomWebxVolume,
  // ]);

  // const setupAudio = async () => {
  //   const audioCtx = window.APP.audioContext;
  //   audioCtxRef.current = audioCtx;

  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       audio: { deviceId: selectedVirtualCableId },
  //     });
  //     streamRef.current = stream;

  //     const sourceNode = audioCtx.createMediaStreamSource(stream);
  //     sourceNodeRef.current = sourceNode;

  //     const gainNode = audioCtx.createGain();

  //     gainNode.gain.value = zoomWebxAudioMute
  //       ? 0
  //       : zoomWebxVolume
  //       ? zoomWebxVolume / 100
  //       : 0.05;

  //     if (monitoringMode) {
  //       // Set the panning to left side
  //       const panNode = audioCtx.createStereoPanner();
  //       panNode.pan.value = 1; // Left side
  //       sourceNode.connect(panNode);
  //       panNode.connect(gainNode);
  //     } else {
  //       // Audio comes from both sides
  //       sourceNode.connect(gainNode);
  //     }

  //     gainNode.connect(audioCtx.destination);

  //     console.log("Audio started");
  //   } catch (error) {
  //     console.error("Error accessing audio stream:", error);
  //   }
  // };
  // const cleanupAudio = () => {
  //   if (sourceNodeRef.current) {
  //     sourceNodeRef.current.disconnect();
  //     sourceNodeRef.current = null;
  //   }

  //   if (streamRef.current) {
  //     const tracks = streamRef.current.getTracks();
  //     tracks.forEach((track) => track.stop());
  //     streamRef.current = null;
  //   }

  //   if (audioCtxRef.current) {
  //     audioCtxRef.current.close();
  //     audioCtxRef.current = null;
  //   }
  // };

  const onLongPress = () => {
    setHoldMute(true);
    let audioTrack = localTracks.find((track) => track.getType() === "audio");
    audioTrack.mute();
  };

  const leveOnPress = () => {
    let audioTrack = localTracks.find((track) => track.getType() === "audio");
    audioTrack.unmute();
    setHoldMute(false);
  };
  const onClick = () => {
    console.log("onClick is triggered");
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(
    onLongPress,
    onClick,
    leveOnPress,
    defaultOptions
  );

  const mainMicInOut = () => {
    let audioTrack = localTracks.find((track) => track.getType() === "audio");
    // console.log("audioTrack>", audioTrack);
    if (!audioTrack) {
      toast.error(commonMessage.audioDeviceNotAvailable);
      return;
    }
    if (mainMicMute) {
      audioTrack?.unmute();
    } else {
      audioTrack?.mute();
    }
    dispatch(setMainMicMuteAction(!mainMicMute));
  };

  const changeBoothmatesMute = () => {
    const room = window.APP?.room;
    if (room) {
      room.setLocalParticipantProperty("boothmatesMute", !boothmatesMute);
    }
    dispatch(setBoothmatesMuteAction(!boothmatesMute));
  };

  const changeBoothmatesVolume = (value) => {
    setBoothmateVolumeState(value);
  };

  const changeBoothmatesVolumeAfterChange = (value) => {
    dispatch(setBoothmateVolumeAction(value));
  };

  const changeBoothmatesAudio = (value) => {
    dispatch(setBoothmateAudioAction(value));
  };

  function changeAudioInput(id) {
    dispatch(setVirtualCableId(id));
  }

  return (
    <div className="row">
      <div className="col-6 col-xs-12 px-3">
        <div className="card" id="audio-controls">
          {/* Boothmate audio */}
          <div className="card-body">
            <h4 className="booth-heading mb-3">Boothmates Audio</h4>
            <div className="mb-3">
              <Meter value={remoteAudioLevel * 200} />
            </div>
            <button
              id="mute-boothmates-btn "
              className={`btn btn-sm btn-block unmute audio-manage-btn mb-3 ${
                boothmatesMute ? "btn-primary" : "btn-secondary"
              }`}
              style={{ height: "4rem" }}
              onClick={() => {
                changeBoothmatesMute();
              }}
            >
              <i
                className={`fas ${
                  boothmatesMute ? "fa-volume-mute" : "fa-volume-up"
                } fa-xl mr-2`}
              ></i>
              Boothmates {boothmatesMute ? "Muted" : "Unmuted"}
            </button>
            <div className="audio-bar">
              <div className="slider slider-horizontal " id="volume-slider">
                <div
                  id="slide-boothmate"
                  className="slider-handle min-slider-handle"
                  role="slider"
                  data-slider-min="0"
                  data-slider-max="100"
                  data-slider-step="1"
                  aria-valuenow="0"
                  data-slider-value="0"
                  tabIndex="0"
                  data-slider-tooltip="show"
                ></div>
              </div>
            </div>
            {/* new design */}
          </div>

          {participant_type === "interpreter" &&
            process.env.REACT_APP_ZOOM_FUNC === "yes" && (
              <>
                <div className="card-body">
                  <h4 className="booth-heading mb-3">
                    Monitoring Audio is ON/OFF
                  </h4>
                  <button
                    id="mute-boothmates-btn "
                    style={{ height: "4rem" }}
                    onClick={() => {
                      dispatch(setMonitoringMode());
                    }}
                  >
                    Monitoring {monitoringMode ? "ON" : "OFF"}
                  </button>
                </div>
                <div className="card-body">
                  <h4 className="booth-heading mb-3">Zoom/webx</h4>
                  {participant_type === "interpreter" && (
                    <div className="col-12 col-lg-6">
                      <div className="form-group">
                        <label>Listen Through</label>
                        <select
                          name="micdevice"
                          id={"audioInputSelect"}
                          onChange={(e) => changeAudioInput(e.target.value)}
                          className="custom-select bg-light border border-dark"
                          defaultValue={selectedVirtualCableId}
                          value={selectedVirtualCableId}
                        >
                          <option value="">Choose...</option>
                          {renderDevicesList("audioinput")}
                        </select>
                        <div className="small text-muted ">
                          Leave as Default if unsure
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="audio-bar">
                    <div
                      className="slider slider-horizontal"
                      id="volume-slider1"
                    >
                      <div
                        id="slide-boothmate1"
                        className="slider-handle min-slider-handle"
                        role="slider"
                        data-slider-min="0"
                        data-slider-max="100"
                        data-slider-step="1"
                        aria-valuenow="0"
                        data-slider-value="0"
                        tabIndex="0"
                        data-slider-tooltip="show"
                      ></div>
                    </div>
                  </div>

                  <button
                    id="mute-boothmates-btn "
                    className={`btn btn-sm btn-block unmute audio-manage-btn mb-3 ${
                      zoomWebxAudioMute ? "btn-primary" : "btn-secondary"
                    }`}
                    style={{ height: "4rem" }}
                    onClick={() => {
                      dispatch(setZoomWebxAudioMute());
                    }}
                  >
                    <i
                      className={`fas ${
                        zoomWebxAudioMute ? "fa-volume-mute" : "fa-volume-up"
                      } fa-xl mr-2`}
                    ></i>
                    zoom/Webx {zoomWebxAudioMute ? "Muted" : "UnMuted"}
                  </button>
                  <audio id="zoomWebxAudio" />
                </div>
              </>
            )}
        </div>
      </div>
      <div className="col-6 col-xs-12">
        <div className="card" id="audio-controls">
          <div className="card-body">
            {/* new design */}
            {/* <div className="audio-badge out-audio">
              <p>Microphone Audio</p>
            </div> */}
            <h4 className="booth-heading mb-3">Microphone Audio</h4>
            <div className="text-left mb-3">
              {/* <BorderLinearProgress variant="determinate" value={remoteAudioLevel * 100} /> */}
              {/* <img alt="video" src="https://prototype.edkelly.com/img/meter.gif" /> */}
              <Meter value={localAudioLevel} />
            </div>
            <div className="mb-2">
              <button
                id="mic-btn"
                className={` btn-sm btn-block audio-manage-btn ${
                  mainMicMute ? "mic-off" : "mic-onn"
                }`}
                style={{ height: "4rem" }}
                onClick={() => {
                  mainMicInOut();
                }}
              >
                <i
                  className={`fas ${
                    mainMicMute ? "fa-microphone-slash" : "fa-microphone"
                  } fa-xl mr-2`}
                ></i>
                {mainMicMute ? "Mic is OFF" : "Mic is ON"}
              </button>
            </div>
            <div>
              <button
                id="cough-btn"
                className={`btn btn-block btn-sm audio-manage-btn ${
                  holdMute ? "btn-danger" : "btn-secondary border border-dark"
                } `}
                style={{ height: "4rem" }}
                {...longPressEvent}
              >
                Cough Cut
              </button>
            </div>

            {/* new design */}

            {/* <div className="text-left">
              <span className="text-muted small audio-level-meter-text">
                Out:
              </span>
              <BorderLinearProgress variant="determinate" value={remoteAudioLevel * 100} />
              <img alt="video" src="https://prototype.edkelly.com/img/meter.gif" />
              <Meter value={localAudioLevel} />
            </div>
            <div className="mb-2">
              <button
                id="mic-btn"
                className={`btn btn-sm btn-block ${
                  mainMicMute ? " text-white mic-off" : "btn-danger btn-blink"
                }`}
                style={{ height: "4rem" }}
                onClick={() => {
                  mainMicInOut();
                }}
              >
                <i
                  className={`fas ${
                    mainMicMute ? "fa-microphone-slash" : "fa-microphone"
                  } fa-xl mr-2`}
                ></i>
                {mainMicMute ? "Mic is OFF" : "Mic is ON"}
              </button>
            </div>
            <div>
              <button
                id="cough-btn"
                className={`btn btn-block btn-sm ${
                  holdMute ? "btn-danger" : "btn-secondary border border-dark"
                } `}
                {...longPressEvent}
              >
                Cough Cut
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainAudioControl;
