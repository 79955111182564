import { useCallback, memo } from "react";
import { useSelector } from "react-redux";

function LargeVideo({ track }) {
  const { remoteTracks, selecteduser, videoEnabled } = useSelector(
    (state) => state.connection
  );
  
  const attachVideo = useCallback(
    (refData) => {
      refData && track && track.attach(refData);
    },
    [track]
  );


  if (
    !selecteduser ||
    (selecteduser === "local" && !videoEnabled) ||
    (selecteduser !== "local" &&
      (!remoteTracks[selecteduser] || track.isMuted()))
  ) {
    return (
      <div
        id="video-viewer-instruction"
        className="font-italic text-muted p-3"
        style={{ display: "block" }}
      >
        View a larger version of an interpreter's video by clicking their
        thumbnail.
      </div>
    );
  }

  return (
    <>
      <video
        className="video-thumb img-fluid largevideo"
        autoPlay="1"
        ref={attachVideo}
        id={`localVideo-largevideo`}
        preload={"auto"}
        poster={`/media/black-screen.svg`}
      />
    </>
  );
}

// function compare(preProps, nextProps) {
//     return (preProps.track.isMuted() !== nextProps.track.isMuted());
// }

// export default memo(LargeVideo, compare);
export default memo(LargeVideo);
// export default LargeVideo;
