import { useSelector } from "react-redux";
import LocalTrack from "./LocalTrack";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { memo, useState } from "react";

function LocalTracks(props) {
  // console.log("JitsiMeetJS==>", JitsiMeetJS)

  const { localTracks, chimeEnabled, boothmatesMute, localStats } = useSelector(
    (state) => state.connection
  );

  // const [showStats, setShowStats] = useState(false);

  let isVideoExist = localTracks.filter((track) => track.getType() === "video");
  let audioTrack = localTracks.find((track) => track.getType() === "audio");

  // function statsUI() {
  //   return (
  //     <div
  //       className="mainLocalStats"
  //       onMouseEnter={() => setShowStats(true)}
  //       onMouseLeave={() => setShowStats(false)}
  //     >
  //       {/* Connection stats icon */}
  //       <div className="signal_icon">
  //         <svg
  //           width="23"
  //           height="23"
  //           viewBox="0 0 23 23"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <g clip-path="url(#clip0_1281_1983)">
  //             <rect width="23" height="23" fill="transparent" />
  //             <path
  //               d="M16.2227 3.19923C16.2227 2.45332 16.8273 1.84863 17.5732 1.84863H19.7848C20.5308 1.84863 21.1355 2.45332 21.1355 3.19923V19.7864C21.1355 20.5323 20.5308 21.137 19.7848 21.137H17.5732C16.8273 21.137 16.2227 20.5323 16.2227 19.7864V3.19923Z"
  //               stroke="CurrentColor"
  //             />
  //             <path
  //               d="M9.03516 9.83398C9.03516 9.08807 9.63983 8.4834 10.3857 8.4834H12.5973C13.3433 8.4834 13.948 9.08807 13.948 9.83398V19.7863C13.948 20.5322 13.3433 21.1369 12.5973 21.1369H10.3857C9.63983 21.1369 9.03516 20.5322 9.03516 19.7863V9.83398Z"
  //               stroke="CurrentColor"
  //             />
  //             <path
  //               d="M1.84766 16.4687C1.84766 15.7228 2.45234 15.1182 3.19825 15.1182H5.40986C6.1558 15.1182 6.76043 15.7228 6.76043 16.4687V19.7862C6.76043 20.5321 6.1558 21.1368 5.40986 21.1368H3.19825C2.45234 21.1368 1.84766 20.5321 1.84766 19.7862V16.4687Z"
  //               stroke="CurrentColor"
  //             />
  //           </g>
  //           <defs>
  //             <clipPath id="clip0_1281_1983">
  //               <rect width="23" height="23" fill="transparent" />
  //             </clipPath>
  //           </defs>
  //         </svg>
  //       </div>
  //       {/* Local stats */}
  //       {showStats ? (
  //         <div className="localStats">
  //           <ul className="localStatsList">
  //             {/* connection */}
  //             <li>
  //               <label>Connection:</label>
  //               <span>Good</span>
  //             </li>
  //             {/* Bitrate */}
  //             <li>
  //               <label>Bitrate: </label>

  //               <div className="bitrate-stats">
  //                 {/* Download */}
  //                 <svg
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   width="76"
  //                   height="15"
  //                   viewBox="0 0 76 115"
  //                   fill="none"
  //                 >
  //                   <path
  //                     d="M33.7496 110.701L33.7496 5.26071C33.7496 2.88996 35.6649 0.974611 38.0357 0.974611C40.4064 0.974611 42.3218 2.88996 42.3218 5.26071L42.3218 100.348L68.333 74.3364C70.0073 72.6622 72.7263 72.6622 74.4006 74.3364C76.0748 76.0107 76.0748 78.7297 74.4006 80.404L41.0627 113.742C39.8305 114.974 37.9955 115.336 36.3882 114.666C34.7943 113.996 33.7496 112.429 33.7496 110.701Z"
  //                     fill="white"
  //                   />
  //                   <path
  //                     d="M0.37078 77.3257C0.37078 76.2274 0.785999 75.1291 1.62983 74.2987C3.30408 72.6244 6.02308 72.6244 7.69734 74.2987L41.0754 107.677C42.7496 109.351 42.7496 112.07 41.0754 113.744C39.4011 115.418 36.6821 115.418 35.0078 113.744L1.62983 80.3662C0.785999 79.5224 0.37078 78.4241 0.37078 77.3257Z"
  //                     fill="white"
  //                   />
  //                 </svg>
  //                 {localStats?.bitrate?.download
  //                   ? `${localStats?.bitrate?.download}Kbps`
  //                   : "N/A"}
  //                 {/* Upload */}
  //                 <svg
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   width="76"
  //                   height="15"
  //                   viewBox="0 0 76 115"
  //                   fill="none"
  //                 >
  //                   <path
  //                     d="M42.2773 5.27328L42.2773 110.714C42.2773 113.085 40.3619 115 37.9912 115C35.6204 115 33.7051 113.085 33.7051 110.714L33.7051 15.6269L7.69381 41.6382C6.01955 43.3124 3.30056 43.3124 1.6263 41.6382C-0.0479624 39.9639 -0.0479626 37.2449 1.6263 35.5706L34.9641 2.23283C36.1964 1.00057 38.0314 0.638931 39.6387 1.30863C41.2326 1.97834 42.2773 3.54544 42.2773 5.27328Z"
  //                     fill="white"
  //                   />
  //                   <path
  //                     d="M75.6561 38.6489C75.6561 39.7472 75.2409 40.8455 74.397 41.6759C72.7228 43.3502 70.0038 43.3502 68.3295 41.6759L34.9515 8.29792C33.2772 6.62366 33.2772 3.90467 34.9515 2.23042C36.6258 0.556157 39.3448 0.556157 41.019 2.23042L74.397 35.6084C75.2409 36.4523 75.6561 37.5506 75.6561 38.6489Z"
  //                     fill="white"
  //                   />
  //                 </svg>
  //                 {localStats?.bitrate?.upload
  //                   ? `${localStats?.bitrate?.upload}Kbps`
  //                   : "N/A"}
  //               </div>
  //             </li>
  //             {/* Packet loss */}
  //             <li>
  //               <label>Packet loss:</label>

  //               <div>
  //                 {/* Download */}
  //                 <svg
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   width="76"
  //                   height="15"
  //                   viewBox="0 0 76 115"
  //                   fill="none"
  //                 >
  //                   <path
  //                     d="M33.7496 110.701L33.7496 5.26071C33.7496 2.88996 35.6649 0.974611 38.0357 0.974611C40.4064 0.974611 42.3218 2.88996 42.3218 5.26071L42.3218 100.348L68.333 74.3364C70.0073 72.6622 72.7263 72.6622 74.4006 74.3364C76.0748 76.0107 76.0748 78.7297 74.4006 80.404L41.0627 113.742C39.8305 114.974 37.9955 115.336 36.3882 114.666C34.7943 113.996 33.7496 112.429 33.7496 110.701Z"
  //                     fill="white"
  //                   />
  //                   <path
  //                     d="M0.37078 77.3257C0.37078 76.2274 0.785999 75.1291 1.62983 74.2987C3.30408 72.6244 6.02308 72.6244 7.69734 74.2987L41.0754 107.677C42.7496 109.351 42.7496 112.07 41.0754 113.744C39.4011 115.418 36.6821 115.418 35.0078 113.744L1.62983 80.3662C0.785999 79.5224 0.37078 78.4241 0.37078 77.3257Z"
  //                     fill="white"
  //                   />
  //                 </svg>
  //                 {localStats?.packetLoss
  //                   ? `${localStats?.packetLoss?.download}%`
  //                   : "N/A"}
  //                 {/* Upload */}
  //                 <svg
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   width="76"
  //                   height="15"
  //                   viewBox="0 0 76 115"
  //                   fill="none"
  //                 >
  //                   <path
  //                     d="M42.2773 5.27328L42.2773 110.714C42.2773 113.085 40.3619 115 37.9912 115C35.6204 115 33.7051 113.085 33.7051 110.714L33.7051 15.6269L7.69381 41.6382C6.01955 43.3124 3.30056 43.3124 1.6263 41.6382C-0.0479624 39.9639 -0.0479626 37.2449 1.6263 35.5706L34.9641 2.23283C36.1964 1.00057 38.0314 0.638931 39.6387 1.30863C41.2326 1.97834 42.2773 3.54544 42.2773 5.27328Z"
  //                     fill="white"
  //                   />
  //                   <path
  //                     d="M75.6561 38.6489C75.6561 39.7472 75.2409 40.8455 74.397 41.6759C72.7228 43.3502 70.0038 43.3502 68.3295 41.6759L34.9515 8.29792C33.2772 6.62366 33.2772 3.90467 34.9515 2.23042C36.6258 0.556157 39.3448 0.556157 41.019 2.23042L74.397 35.6084C75.2409 36.4523 75.6561 37.5506 75.6561 38.6489Z"
  //                     fill="white"
  //                   />
  //                 </svg>
  //                 {localStats?.packetLoss
  //                   ? `${localStats?.packetLoss?.upload}%`
  //                   : "N/A"}
  //               </div>
  //             </li>
  //           </ul>
  //         </div>
  //       ) : null}
  //     </div>
  //   );
  // }

  if (!isVideoExist.length || !localTracks.length) {
    return (
      <>
        <LazyLoadImage
          className="video-thumb img-fluid noImage-thumb"
          id={`local-video-thumb-0001`}
          alt="video"
          src="/media/noPicture.svg"
          effect="blur"
        />
        {/* {statsUI()} */}
        <div className="video-controls">
          <i
            className={`${
              chimeEnabled
                ? "fa-solid fa-bell-on"
                : "fa-solid fa-bell-slash text-danger"
            }`}
          ></i>
          <i
            className={` ${
              boothmatesMute
                ? "fa-solid fa-ear-deaf text-danger"
                : "fa-solid fa-ear "
            }`}
          ></i>
          <i
            className={` ${
              audioTrack == undefined || audioTrack?.isMuted()
                ? "fa-solid fa-microphone-slash"
                : "fa-solid fa-microphone text-danger"
            }`}
          ></i>
        </div>
      </>
    );
  }

  return localTracks.map((localtrack, index) => {
    return (
      <LocalTrack
        track={localtrack}
        isAudioMuted={audioTrack?.isMuted()}
        chimeEnabled={chimeEnabled}
        boothmatesMute={boothmatesMute}
        JitsiMeetJS={props.JitsiMeetJS}
        room={props.room}
        key={index}
        index={index}
      />
    );
  });
}

export default memo(LocalTracks);
