import React, { useCallback, memo, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelecteduserAction } from "../Redux/Reducers/Connection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import RemoteTrackStats from "./remoteTrackStats";

// async function createLoopbackStream(stream) {
//   let rtcConnection = null;
//   let rtcLoopbackConnection = null;
//   let loopbackStream = new MediaStream(); // this is the stream you will read from for actual audio output
//   let localStream = new MediaStream();
//   const offerOptions = {
//     offerVideo: true,
//     offerAudio: true,
//     offerToReceiveAudio: false,
//     offerToReceiveVideo: false,
//   };

//   let offer, answer;

//   // initialize the RTC connections

//   rtcConnection = new RTCPeerConnection();
//   rtcLoopbackConnection = new RTCPeerConnection();

//   rtcConnection.onicecandidate = (e) =>
//     e.candidate &&
//     rtcLoopbackConnection.addIceCandidate(new RTCIceCandidate(e.candidate));
//   rtcLoopbackConnection.onicecandidate = (e) =>
//     e.candidate &&
//     rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate));

//   rtcLoopbackConnection.ontrack = (e) => {
//     console.log("   e.streams ", e.track, e.streams);
//     e.streams[0].getTracks().forEach((track) => loopbackStream.addTrack(track));
//   };

//   // setup the loopback

//   stream.stream
//     .getTracks()
//     .forEach((track) => rtcConnection.addTrack(track, localStream)); // this stream would be the processed stream coming out of Web Audio API destination node

//   offer = await rtcConnection.createOffer(offerOptions);
//   await rtcConnection.setLocalDescription(offer);

//   await rtcLoopbackConnection.setRemoteDescription(offer);
//   answer = await rtcLoopbackConnection.createAnswer();
//   await rtcLoopbackConnection.setLocalDescription(answer);

//   await rtcConnection.setRemoteDescription(answer);

//   return loopbackStream;
// }

// let mergerNode;
// let gainNodeLeft;

function RemoteTrack({
  track,
  id,
  index,
  isVideoExist,
  isAudioMuted,
  isJoined,
  remoteUserData,
  room,
  JitsiMeetJS,
}) {
  const {
    boothmatesMute,
    boothmateVolume,
    audioOutputDeviceId,
    brickLimiter,
    thresholdValue,
    attackValue,
    releaseValue,
    pregainValue,
    postgainValue,
    monitoringMode,
  } = useSelector((state) => state.connection);
  const dispatch = useDispatch();
  const [audioElementState, setAudioElementState] = useState(null);
  const selectUserForLargeVideo = useCallback(() => {
    dispatch(setSelecteduserAction(id));
  }, [dispatch, setSelecteduserAction, id]);

  const attachVideo = useCallback(
    (refData) => {
      refData && track && track.attach(refData);
    },
    [track]
  );

  const attachAudio = useCallback(
    async (refData) => {
      if (refData && window.APP.audioContext) {
        refData.muted = true;
        track.attach(refData);

        let audioCtx = window.APP.audioContext;

        const source = audioCtx.createMediaStreamSource(track.stream);
        const dest = audioCtx.createMediaStreamDestination();

        if (brickLimiter) {
          const limiter = window.APP.limiter;

          source.connect(limiter);
          limiter.connect(dest);

          limiter.isReady.then(() => {
            //Atack
            limiter.attack.setValueAtTime(attackValue, audioCtx.currentTime); // set value 100ms in 10 second

            //Release
            limiter.release.setValueAtTime(releaseValue, audioCtx.currentTime);

            //Threshold
            limiter.threshold.setValueAtTime(
              thresholdValue,
              audioCtx.currentTime
            );

            //Pregain
            limiter.preGain.setValueAtTime(pregainValue, audioCtx.currentTime);

            //Postgain
            limiter.postGain.setValueAtTime(
              postgainValue,
              audioCtx.currentTime
            );
          });
        } else {
          source.connect(dest);
        }

        let audioElement = document.getElementById(
          `${id}-audio-${index}_process`
        );
        audioElement.srcObject = dest.stream;
        if (!audioElementState) {
          setAudioElementState(audioElement);
        }
      }
    },
    [
      track,
      isJoined,
      id,
      index,
      audioElementState,
      brickLimiter,
      thresholdValue,
      attackValue,
      releaseValue,
      pregainValue,
      postgainValue,
    ]
  );

  // useEffect(() => {
  //   if (brickLimiter && mergerNode) {
  //     console.log("Enter to disconnect");
  //     mergerNode.disconnect();
  //   }
  // }, [brickLimiter, mergerNode]);

  // const attachAudio = useCallback(
  //   async (refData) => {
  //     if (refData && window.APP.audioContext) {
  //       refData.muted = true;
  //       track.attach(refData);

  //       let audioCtx = window.APP.audioContext;

  //       const source = audioCtx.createMediaStreamSource(track.stream);
  //       const dest = audioCtx.createMediaStreamDestination();

  //       if (brickLimiter) {
  //         const limiter = window.APP.limiter;

  //         //create a channel splitter node
  //         const splitter = audioCtx.createChannelSplitter(2); // Splitting into 2 channels

  //         // Create a channel merger node
  //         const merger = audioCtx.createChannelMerger(2); // Merging back into 2 channels

  //         // Connect the source to the splitter
  //         source.connect(splitter);

  //         if (monitoringMode) {
  //           // Connect only the right channel to the limiter and then to destination
  //           splitter.connect(limiter, 0, 0); // Connect only left channel (index:) for processing
  //           limiter.connect(merger, 0, 0); // Connect processed output back to left channel of merger
  //         } else {
  //           splitter.connect(merger, 0, 0);
  //           splitter.connect(merger, 1, 1);
  //         }

  //         // Connect output of merger to destination
  //         merger.connect(dest);

  //         limiter.isReady.then(() => {
  //           //Atack
  //           limiter.attack.setValueAtTime(attackValue, audioCtx.currentTime); // set value 100ms in 10 second

  //           //Release
  //           limiter.release.setValueAtTime(releaseValue, audioCtx.currentTime);

  //           //Threshold
  //           limiter.threshold.setValueAtTime(
  //             thresholdValue,
  //             audioCtx.currentTime
  //           );

  //           //Pregain
  //           limiter.preGain.setValueAtTime(pregainValue, audioCtx.currentTime);

  //           //Postgain
  //           limiter.postGain.setValueAtTime(
  //             postgainValue,
  //             audioCtx.currentTime
  //           );
  //         });
  //       } else {
  //         if (monitoringMode) {
  //           let splitterNode1 = audioCtx.createChannelSplitter(2);

  //           // Connect source nodes to their respective splitter
  //           source.connect(splitterNode1);

  //           // Initialize gain nodes for volume control of each channel
  //           gainNodeLeft = audioCtx.createGain();

  //           // Initialize merger node to combine channels into one
  //           mergerNode = audioCtx.createChannelMerger(2);

  //           //connect only to right channel
  //           splitterNode1.connect(gainNodeLeft, 0, 0);

  //           gainNodeLeft.connect(mergerNode, 0, 0);

  //           mergerNode.connect(dest);
  //         } else {
  //           source.connect(dest);
  //         }
  //       }

  //       let audioElement = document.getElementById(
  //         `${id}-audio-${index}_process`
  //       );
  //       audioElement.srcObject = dest.stream;
  //       if (!audioElementState) {
  //         setAudioElementState(audioElement);
  //       }
  //     }
  //   },
  //   [
  //     track,
  //     isJoined,
  //     id,
  //     index,
  //     monitoringMode,
  //     audioElementState,
  //     brickLimiter,
  //     thresholdValue,
  //     attackValue,
  //     releaseValue,
  //     pregainValue,
  //     postgainValue,
  //   ]
  // );

  useEffect(() => {
    let audioElement = document.getElementById(`${id}-audio-${index}_process`);
    if (audioElement && audioElementState) {
      if (audioElement.setSinkId) {
        audioElement.setSinkId(audioOutputDeviceId);
      }
      audioElement.muted = boothmatesMute ? true : false;
      audioElement.volume = boothmateVolume ? boothmateVolume / 100 : 0.05;
      audioElement.play();
    }
    // if (gainNodeLeft) {
    //   gainNodeLeft.gain.value = boothmatesMute ? 0 : 1;
    // }
  }, [
    id,
    index,
    audioElementState,
    boothmateVolume,
    boothmatesMute,
    audioOutputDeviceId,
  ]);

  if (track.getType() === "video") {
    if (track.isMuted()) {
      return (
        <React.Fragment>
          <LazyLoadImage
            className="video-thumb noImage-thumb img-fluid"
            id={`${id}-video-thumb-${index}`}
            alt="video2"
            src="/media/noPicture.svg"
            effect="blur"
          />

          {/* Remote stats */}
          {/* <RemoteTrackStats
            id={remoteUserData._id}
            room={room}
            JitsiMeetJS={JitsiMeetJS}
          /> */}

          {/* Video controls */}
          <div className="video-controls">
            <i
              className={`${
                remoteUserData._properties.chimeEnabled === "true"
                  ? "fa-solid fa-bell-on"
                  : "fa-solid fa-bell-slash text-danger"
              }`}
            ></i>
            <i
              className={` ${
                remoteUserData._properties.boothmatesMute === "true"
                  ? "fa-solid fa-ear-deaf text-danger"
                  : "fa-solid fa-ear "
              }`}
            ></i>
            <i
              className={` ${
                isAudioMuted == undefined || isAudioMuted
                  ? "fa-solid fa-microphone-slash"
                  : "fa-solid fa-microphone text-danger"
              }`}
            ></i>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <video
          onClick={selectUserForLargeVideo}
          className="video-thumb img-fluid "
          autoPlay="1"
          ref={attachVideo}
          id={`${id}-video-${index}`}
          poster={`/media/black-screen.svg`}
        />
        {/* Remote stats */}
        {/* <RemoteTrackStats
          id={remoteUserData._id}
          room={room}
          JitsiMeetJS={JitsiMeetJS}
        /> */}
        <div className="video-controls">
          <i
            className={`${
              remoteUserData._properties.chimeEnabled === "true"
                ? "fa-solid fa-bell-on"
                : "fa-solid fa-bell-slash text-danger"
            }`}
          ></i>
          <i
            className={` ${
              remoteUserData._properties.boothmatesMute === "true"
                ? "fa-solid fa-ear-deaf text-danger"
                : "fa-solid fa-ear "
            }`}
          ></i>
          <i
            className={` ${
              isAudioMuted == undefined || isAudioMuted
                ? "fa-solid fa-microphone-slash"
                : "fa-solid fa-microphone text-danger"
            }`}
          ></i>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <>
        {!isVideoExist.length && (
          <>
            <LazyLoadImage
              className="video-thumb noImage-thumb img-fluid"
              id={`${id}-video-thumb-${index}`}
              alt="video"
              src="/media/noPicture.svg"
              effect="blur"
            />

            {/* Remote stats */}
            {/* <RemoteTrackStats
              id={remoteUserData._id}
              room={room}
              JitsiMeetJS={JitsiMeetJS}
            /> */}

            {/* Video controls */}
            <div className="video-controls">
              <i
                className={`${
                  remoteUserData._properties.chimeEnabled === "true"
                    ? "fa-solid fa-bell-on"
                    : "fa-solid fa-bell-slash text-danger"
                }`}
              ></i>
              <i
                className={` ${
                  remoteUserData._properties.boothmatesMute === "true"
                    ? "fa-solid fa-ear-deaf text-danger"
                    : "fa-solid fa-ear "
                }`}
              ></i>
              <i
                className={` ${
                  isAudioMuted == undefined || isAudioMuted
                    ? "fa-solid fa-microphone-slash"
                    : "fa-solid fa-microphone text-danger"
                }`}
              ></i>
            </div>
          </>
        )}
        <audio autoPlay="1" ref={attachAudio} id={`${id}-audio-${index}`} />
        <audio autoPlay="1" id={`${id}-audio-${index}_process`} />
      </>
    );
  }
}

function compare(preProps, nextProps) {
  return preProps.track.isMuted() !== nextProps.track.isMuted();
}

export default memo(RemoteTrack, compare);
